import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import Container from 'reactstrap/lib/Container';

import {
  isMobile,
} from 'react-device-detect';

import { Helmet } from 'react-helmet';
import { FONT_MODE } from '../../../constants/constants';

const Template = ({
  pageTitle, addClassName, children, isFullscreen, fontMode,
}) => (
  !isMobile ? (
    <div className="test" style={isFullscreen ? { height: '100vh', width: '100vw' } : {}}>
      <div className={`${isFullscreen ? 'overflow-manage' : ''}`} >
        <Container className={`landing-container ${addClassName} root-font-${fontMode}`}>
          <div className="landing-bg" />
          <Helmet>
            <title>{pageTitle}</title>
          </Helmet>

          <Fragment>{children}</Fragment>
        </Container>
      </div>
    </div>
  ) : (
    <div className="test" style={isFullscreen ? { height: '100%', width: '100vw' } : {}}>
      <div className={`${isFullscreen ? 'overflow-manage' : ''}`} >
        <Container className={`landing-container landing-container-mobile ${addClassName} root-font-${fontMode}`}>
          <div className="landing-bg" />
          <Helmet>
            <title>{pageTitle}</title>
          </Helmet>

          <Fragment>{children}</Fragment>
        </Container>
      </div>
    </div>
  )
);

Template.propTypes = {
  pageTitle: PropTypes.string,
  addClassName: PropTypes.string,
  isFullscreen: PropTypes.bool,
  children: PropTypes.element.isRequired,
  fontMode: PropTypes.string,
};

Template.defaultProps = {
  pageTitle: 'Zole',
  addClassName: '',
  isFullscreen: false,
  fontMode: FONT_MODE.normal,
};

export default Template;
