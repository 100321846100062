import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation, withTranslation } from 'react-i18next';

import {
  Row,
  Col,
  Label,
  Alert,
  Input,
  Button,
} from 'reactstrap';

import { TextInput } from '../../Components/Components/TextInput';
import * as constants from '../../../../../constants/constants';
import { generalMessages } from '../../../../../constants/messages';
import CustomModal from '../../Components/Components/Modal';

const RedeemGiftCode = React.memo(({
  code, giftMessage, giftStatus, selectCode, selectValue, handleInputChange, giftMessageVisible, verifyGift, loading,
}) => {
  const { t } = useTranslation('common');

  return (
    // <Row className="buy-page-redeemGiftCode">
    //   <div style={{height: '30vh', width: '70vw', background: 'rgba(255,0,0,0.5)'}}>
    //     <Row className="buy-page-redeemGiftCode-section-inputCode">
    //       <Col xs="12" className="buy-page-redeemGiftCode-section-inputCode-col">
    //         <TextInput
    //           maxLength="20"
    //           contentEditable
    //           width={320.6}
    //           initialValue={code}
    //           selectCode={selectCode}
    //           selectValue={selectValue}
    //           inputStatus={giftStatus}
    //           inputMessage={giftMessage === generalMessages.verifySuccessMessage ? t('common.verifySuccessMessage', { amount: selectValue }) : giftMessage === generalMessages.claimSuccessMessage ? t('common.claimSuccessMessage', { code: selectCode }) : t(`common.${giftMessage}`)}
    //           inlineMessageVisible={giftMessageVisible}
    //           secondaryPlaceholder={t(`common.${generalMessages.enterGiftCode}`)}
    //           onChange={(value) =>
    //             handleInputChange(value)
    //           }
    //           autoFocus
    //         />
    //       </Col>
    //     </Row>
    //     <Row className="buy-page-redeemGiftCode-section-buttonSubmit">
    //       <Col xs="12" className="buy-page-redeemGiftCode-section-buttonSubmit-col">
    //         <Button
    //           disabled={loading}
    //           color="link"
    //           className="layout-highlighted-button buy-page-redeemGiftCode-section-buttonSubmit-col-button"
    //           // onClick={giftStatus === constants.FROUNT_SIDE_STATUS.success ? () => claimGift() : () => verifyGift()}
    //           onClick={() => verifyGift()}
    //         >
    //           {loading ? t('home.loading') : t('common.redeemGiftCode')}
    //         </Button>
    //       </Col>
    //     </Row>
    //   </div>
    // </Row>

    <Row className="buy-page-redeemGiftCode">
      <Col xs="12" className="buy-page-redeemGiftCode-section">
        <Row className="buy-page-redeemGiftCode-section-message">
          <Col xs="12" className="buy-page-redeemGiftCode-section-message-label">
            {loading ? t('home.loading') : (giftStatus === constants.FROUNT_SIDE_STATUS.completed ? (
              <>
                {
                  giftMessage === generalMessages.verifySuccessMessage ? (
                    <p>{t('common.verifySuccessMessage', { amount: selectValue })}</p>
                  ) : giftMessage === generalMessages.claimSuccessMessage ? (
                    <>
                      <p className="buy-page-redeemGiftCode-section-message-label-content1">
                        {t('common.claimSuccessMessage1')}{' '}
                        <span className="buy-page-redeemGiftCode-section-message-label-code">{selectCode}</span>
                      </p>
                      
                      <p className="buy-page-redeemGiftCode-section-message-label-content2">
                        {t('common.claimSuccessMessageMore1')}{' '}
                        <span className="buy-page-redeemGiftCode-section-message-label-code">{`${selectValue} ${t('common.coins')}`}</span>

                      </p>
                    </>
                  ) : (
                    <p>{t(`common.${generalMessages.enterGiftCode}`)}</p>
                  )
                }
              </>
            ) : t(`common.${generalMessages.enterGiftCode}`))}
          </Col>
        </Row>
        {
          (giftStatus !== constants.FROUNT_SIDE_STATUS.completed || true) && (
            <>
              <Row className="buy-page-redeemGiftCode-section-inputCode">
                <Col xs="12" className="buy-page-redeemGiftCode-section-inputCode-col">
                  <TextInput
                    maxLength="20"
                    contentEditable
                    width={320.6}
                    initialValue={code}
                    selectCode={selectCode}
                    selectValue={selectValue}
                    inputStatus={giftStatus}
                    inputMessage={giftMessage === generalMessages.verifySuccessMessage ? t('common.verifySuccessMessage', { amount: selectValue }) : giftMessage === generalMessages.claimSuccessMessage ? t('common.claimSuccessMessage', { code: selectCode }) : t(`common.${giftMessage}`)}
                    inlineMessageVisible={giftMessageVisible}
                    secondaryPlaceholder={t(`common.${generalMessages.enterGiftCode}`)}
                    onChange={(value) =>
                      handleInputChange(value)
                    }
                    autoFocus
                  />
                </Col>
              </Row>
              <Row className="buy-page-redeemGiftCode-section-buttonSubmit">
                <Col xs="12" className="buy-page-redeemGiftCode-section-buttonSubmit-col">
                  <Button
                    disabled={loading}
                    color="link"
                    className="layout-highlighted-button buy-page-redeemGiftCode-section-buttonSubmit-col-button"
                    // onClick={giftStatus === constants.FROUNT_SIDE_STATUS.success ? () => claimGift() : () => verifyGift()}
                    onClick={() => verifyGift()}
                  >
                    {loading ? t('home.loading') : t('common.redeemGiftCode')}
                  </Button>
                </Col>
              </Row>
            </>
          )
        }
      </Col>
    </Row>
  );
});

RedeemGiftCode.propTypes = {
  code: PropTypes.string,
  giftStatus: PropTypes.bool,
  giftMessage: PropTypes.string,
  selectCode: PropTypes.string,
  selectValue: PropTypes.string,
  handleInputChange: PropTypes.func.isRequired,
  giftMessageVisible: PropTypes.bool,
  verifyGift: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  t: PropTypes.func.isRequired,
};

RedeemGiftCode.defaultProps = {
  code: '',
  giftStatus: false,
  giftMessage: '',
  selectCode: '',
  selectValue: '',
  giftMessageVisible: false,
  loading: false,
};

export default (withTranslation('common')(RedeemGiftCode));
