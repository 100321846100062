import React from 'react';
import PropTypes from 'prop-types';

const Tournaments = () => (
  <>
    <p>
      Izstrādes procesā
    </p>
  </>
);

export default Tournaments;
