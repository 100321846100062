import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { Link, withRouter } from 'react-router-dom';


import { Firebase } from '../../lib/firebase';

import { PageView } from '../../web/components/Tracking';

import {
  getCards,
  getAutomatedStatus,
  playCard,
  getCurrentTable,
  getGlobalParams,
  selectGameType,
  getPlayers,
  getPlayerPosition,
  getCurrentTurn,
  getSittingOut,
  getLargePlayer,
  getCurrentType,
  getNextTimeStamp,
  getUnlimitedTimerStarted,
  setLastRound,
  quitRound,
  resetGameStore,
  closeResultNotification,
  setCurRoomId,
  fetchInitialRoomData,
  refreshLastToken,
  getCardsOnTable,
} from '../../actions/game';

import {
  sendSupportMessage,
  setSupportAsRead,
  blockUser,
  unBlockUser,
  updateUserLastLogin,
  closeLevelNotification,
  removeActiveRoom,
  getTimeOffset,
  getIgnoredPlayers,
} from '../../actions/member';

import {
  endRoom2,
  leaveRoom,
  sendGift,
  getGifts,
  getRoomGifts,
  getProfanitiesList,
  readChat,
} from '../../actions/room';

import { getPoints, cancelPointsListeners, getPules } from '../../actions/points';

import { toggleScoreTable } from '../../actions/userSettings';

import { setLoading } from '../../actions/state';

import buttonClickedSound from '../../sounds/button.mp3';
import putCardOnTableSound from '../../sounds/put_card_on_table.mp3';
import { FONT_MODE } from '../../constants/constants';
import { getCampaignBanner } from '../../actions/shop';

class Zole extends Component {
  static propTypes = {
    Layout: PropTypes.func.isRequired,
    //  game: PropTypes.shape().isRequired,
    member: PropTypes.shape().isRequired,
    history: PropTypes.shape(),
    match: PropTypes.shape({
      params: PropTypes.shape({
        id: PropTypes.string,
      }),
    }),
    state: PropTypes.shape({
      isLoading: PropTypes.bool,
    }),
    fetchCards: PropTypes.func.isRequired,
    playACard: PropTypes.func.isRequired,
    lastRoundFunction: PropTypes.func.isRequired,
    fetchCurrentTable: PropTypes.func.isRequired,
    fetchGlobalParams: PropTypes.func.isRequired,
    fetchPlayers: PropTypes.func.isRequired,
    fetchPlayerPosition: PropTypes.func.isRequired,
    chooseGameType: PropTypes.func.isRequired,
    fetchCurrentTurn: PropTypes.func.isRequired,
    fetchSittingOut: PropTypes.func.isRequired,
    fetchLargePlayer: PropTypes.func.isRequired,
    fetchCurrentType: PropTypes.func.isRequired,
    fetchNextTimeStamp: PropTypes.func.isRequired,
    fetchUnlimitedTimerStarted: PropTypes.func.isRequired,
    endThisRoom: PropTypes.func.isRequired,
    leaveThisRoom: PropTypes.func.isRequired,
    quitThisRound: PropTypes.func.isRequired,
    resetStore: PropTypes.func.isRequired,
    closeResultNotif: PropTypes.func.isRequired,
    //  setChatMessageAsRead: PropTypes.func.isRequired,
    sendSupportMsg: PropTypes.func.isRequired,
    setSupportRead: PropTypes.func.isRequired,
    showNotification: PropTypes.func.isRequired,
    sendAGift: PropTypes.func.isRequired,
    fetchGifts: PropTypes.func.isRequired,
    fetchRoomGifts: PropTypes.func.isRequired,
    blockThisUser: PropTypes.func.isRequired,
    unBlockThisUser: PropTypes.func.isRequired,
    updateLastLogin: PropTypes.func.isRequired,
    closeLevelUpNotification: PropTypes.func.isRequired,
    removeCurActiveRoom: PropTypes.func.isRequired,
    setLoadingFunc: PropTypes.func.isRequired,
    fetchIgnoredPlayers: PropTypes.func.isRequired,
    fetchAutomatedStatus: PropTypes.func.isRequired,
    toggleFullscreen: PropTypes.func.isRequired,
    lastRoom: PropTypes.string,
    myPos: PropTypes.string,
    globalParams: PropTypes.shape(),
    userSettings: PropTypes.shape(),
    toggleScoreTableFunc: PropTypes.func.isRequired,
    currentType: PropTypes.string,
    cards: PropTypes.arrayOf(PropTypes.string),
    automated: PropTypes.bool,
    isFullscreen: PropTypes.bool,
    isFullscreenEnabled: PropTypes.bool,
    isWebVersion: PropTypes.bool,
    //  setProfanitiesNotif: PropTypes.func.isRequired,
    profanitiesList: PropTypes.shape(),
    profanitiesNotification: PropTypes.string,
    notificationSettings: PropTypes.shape(),
    gameSettings: PropTypes.shape(),
    setRoomId: PropTypes.func.isRequired,
    fetchInitialData: PropTypes.func.isRequired,
    refreshDbLastToken: PropTypes.func.isRequired,
    getOffset: PropTypes.func.isRequired,
    fetchProfanitiesList: PropTypes.func.isRequired,
    cardsOnTable: PropTypes.arrayOf(PropTypes.string),
    fetchCardsOnTable: PropTypes.func.isRequired,
    toggleNewDesign: PropTypes.func.isRequired,
    toggleScreenMode: PropTypes.func.isRequired,
    fontMode: PropTypes.string,
    toggleFont: PropTypes.func.isRequired,
    lastRound: PropTypes.bool,
    designPlatform: PropTypes.shape({}),
  };

  static defaultProps = {
    match: null,
    history: {},
    state: {},
    globalParams: {},
    userSettings: {},
    lastRoom: null,
    myPos: null,
    currentType: PropTypes.string,
    cards: null,
    automated: false,
    isFullscreen: false,
    isFullscreenEnabled: false,
    isWebVersion: false,
    profanitiesList: null,
    profanitiesNotification: null,
    notificationSettings: null,
    gameSettings: null,
    cardsOnTable: [],
    fontMode: FONT_MODE.normal,
    lastRound: false,
  };

  constructor(props) {
    let devMode = false;

    try {
      const { hostname } = window.location;
      if (hostname && (hostname.includes('dev') || hostname.includes('localhost'))) {
        devMode = true;
      }
    } catch (err) {}
    super(props);
    this.state = {
      error: null,
      roomId: '',
      loading: true,
      //  playersLoading: true,
      uid: '',
      roomClosing: false,
      //  closeReason: null,
      closeErrorSubmit: false,
      cardPlayed: false,
      quitRoundPressed: false,
      errorNotification: null,
      showEndResultModal: false,
      //  supportChatFetched: false,
      currentTableLoaded: true,
      cardsOnLoading: true,
      devMode,
    };

    //  this.closeResultNotif = this.closeResultNotif.bind(this);
    //  this.resetCloseErrorSubmit = this.resetCloseErrorSubmit.bind(this);

    this.buttonClickedAudio = new Audio(buttonClickedSound);
    this.putCardOnTableAudio = new Audio(putCardOnTableSound);
  }


  componentWillMount = () => {
    const {
      match,
      fetchPlayerPosition,
      fetchLargePlayer,
      fetchCurrentTable,
      fetchCurrentTurn,
      fetchSittingOut,
      fetchCurrentType,
      fetchGlobalParams,
      fetchNextTimeStamp,
      fetchUnlimitedTimerStarted,
      resetStore,
      lastRoom,
      setRoomId,
      // fetchInitialData,
      refreshDbLastToken,
      fetchIgnoredPlayers,
      getOffset,
      fetchAutomatedStatus,
      fetchProfanitiesList,
      fetchCardsOnTable,
    } = this.props;

    console.log('componentWillMount');

    const unsubscribe = Firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        const roomId = match && match.params && match.params.id ? match.params.id : null;

        if (roomId) {
        //  let lastRoom = null;
        //  if (game) {
        //    lastRoom = game.lastRoom;
        //  }

          resetStore(lastRoom, roomId).then(() => {
            getOffset();
            refreshDbLastToken();

            // fetchInitialData(roomId);
            fetchProfanitiesList();

            fetchCurrentTable(roomId).then(() => {
              this.setState({ currentTableLoaded: true });
            });
            fetchCurrentTurn(roomId);
            fetchSittingOut(roomId);
            fetchCurrentType(roomId);
            fetchGlobalParams(roomId);
            fetchNextTimeStamp(roomId);
            fetchUnlimitedTimerStarted(roomId);

            this.fetchPlayers(roomId);

            fetchLargePlayer(roomId);
            fetchPlayerPosition(roomId).then(() => {
              this.fetchCards(roomId);
              fetchAutomatedStatus(roomId);
            });
            this.fetchRoomGifts(roomId);

            setRoomId(roomId);

            fetchIgnoredPlayers();
            this.fetchCardsOnTable(roomId);
            this.setState({ roomId, uid: user.uid });
          }).catch((err) => {
            console.log(err);

            // fetchInitialData(roomId);
            fetchProfanitiesList();

            fetchCurrentTable(roomId).then(() => {
              this.setState({ currentTableLoaded: true });
            });
            fetchCurrentTurn(roomId);
            fetchSittingOut(roomId);
            fetchCurrentType(roomId);
            fetchGlobalParams(roomId);
            fetchNextTimeStamp(roomId);
            fetchUnlimitedTimerStarted(roomId);

            this.fetchPlayers(roomId);

            fetchLargePlayer(roomId);

            fetchPlayerPosition(roomId).then(() => {
              this.fetchCards(roomId);
              fetchAutomatedStatus(roomId);
            });
            this.fetchRoomGifts(roomId);

            fetchIgnoredPlayers();
            this.fetchCardsOnTable(roomId);
            this.setState({ roomId, uid: user.uid });
          });
        }
      }
    });
  };

  componentDidMount() {
    const { fetchCampaign, newDesign } = this.props;
    const { devMode } = this.state;

    PageView();
   //  const design = newDesign ? 'new' : 'old';
   //  console.log('fetchCampaign(openRoom, design, devMode)', 'openRoom', design, devMode);
    // fetchCampaign('openRoom');
     // fetchCampaign('openRoom', design, devMode);
  }

  componentDidUpdate(prevProps) {
    const { myPos, member, history, globalParams, players } = this.props;
    const { roomId } = this.state;

    if (prevProps.globalParams && this.props && prevProps.myPos !== myPos) {
      this.fetchCards(roomId);
    }

    console.log('ZOLE componentDidUpdate', member, member.activeRoom, roomId);

    if (roomId && member && member.activeRoom && member.activeRoom !== roomId) {
      console.log('member.activeRoom chganged, redirect to the new room');
      this.setState({ roomId: member.activeRoom });
      history.push(`/zole/${member.activeRoom}`);

      this.refetchData(member.activeRoom);
    }

    if (roomId && member && member.role !== 'admin' && !member.activeRoom && globalParams && !globalParams.roomClosed, players && players.playerList && member.uid) {
      console.log('No member.activeRoom data, check to redirect', globalParams, roomId, member.activeRoom, players);

      if (players.player1 && players.player1.uid && players.player2 && players.player2.uid && players.player3 && players.player3.uid) {
        if (globalParams.gameState && globalParams.party && globalParams.roomId && globalParams.roomId === roomId) {
          if ((!players.player1 || players.player1.uid !== member.uid) && (!players.player2 || players.player2.uid !== member.uid)
            && (!players.player3 || players.player3.uid !== member.uid) && (!players.player4 || players.player4.uid !== member.uid)) {

            console.log('Call history.push for redirect as not in correct room', globalParams.roomId, roomId, member.activeRoom);

            history.push(`/`);
          }
        }
      }

      //  if (globalParams.gameState && globalParams.party && globalParams.roomId && globalParams.roomId === roomId) {
      //    console.log('Call history.push for redirect as not in correct room', globalParams.roomId, roomId, member.activeRoom);

      //    history.push(`/`);
      //  }
    } else if (roomId && member && member.role !== 'admin' && !member.activeRoom && globalParams && globalParams.roomId && globalParams.roomId === roomId && !players && globalParams.gameState && globalParams.party && globalParams.roomClosed) {
      console.log('Call history.push for redirect as not in correct room 222', globalParams, roomId, member.activeRoom, players);

      history.push(`/`);
    }
  }

  componentWillUnmount() {
    clearTimeout(this.endTimeoutId);
  }

  //  sleep = (milliseconds) => {
  //    return new Promise(resolve => setTimeout(resolve, milliseconds))
  //  }

  refetchData = (newRoomId) => {
    const {
      fetchLargePlayer,
      fetchCurrentTable,
      fetchCurrentTurn,
      fetchCurrentType,
      fetchGlobalParams,
      fetchNextTimeStamp,
      fetchUnlimitedTimerStarted,
      fetchCardsOnTable,
      fetchPlayerPosition,
      fetchAutomatedStatus,
      fetchRoomGifts,
      fetchSittingOut,

      cancelListeners,
      fetchPules,
      fetchPoints,
      readChatMessages,
      setRoomId,
    } = this.props;

    const { roomId } = this.state;

    if (newRoomId) {
      fetchCurrentTable(newRoomId).then(() => {
        this.setState({ currentTableLoaded: true });
      });
      fetchCurrentTurn(newRoomId);
      fetchSittingOut(newRoomId);
      fetchCurrentType(newRoomId);
      fetchGlobalParams(newRoomId);
      fetchNextTimeStamp(newRoomId);
      fetchUnlimitedTimerStarted(newRoomId);

      this.fetchPlayers(newRoomId);

      fetchLargePlayer(newRoomId);
      fetchPlayerPosition(newRoomId).then(() => {
        this.fetchCards(newRoomId);
        fetchAutomatedStatus(newRoomId);
      });
      this.fetchRoomGifts(newRoomId);

      setRoomId(newRoomId);
      this.fetchCardsOnTable(newRoomId);

      cancelListeners().then(() => {
        fetchPules(newRoomId);
        fetchPoints(newRoomId);
      });
      readChatMessages(newRoomId);

      this.setState({ roomId: newRoomId, showEndResultModal: false });




    } else if (roomId) {
      fetchCurrentTable(roomId).then(() => {
        this.setState({ currentTableLoaded: true });
      });
      fetchCurrentTurn(roomId);
      fetchCurrentType(roomId);
      fetchGlobalParams(roomId);
      fetchNextTimeStamp(roomId);
      fetchUnlimitedTimerStarted(roomId);

      fetchLargePlayer(roomId);

      fetchPlayerPosition(roomId).then(() => {
        this.fetchCards(roomId);
        fetchAutomatedStatus(roomId);
      });
      this.fetchRoomGifts(roomId);

      this.fetchCardsOnTable(roomId);

      this.fetchCards(roomId);
    }
  }

  fetchCards = (roomId) => {
    const { fetchCards } = this.props;

    return fetchCards(roomId).catch(err => this.setState({
      loading: false,
      error: err,
    }));
  };

  fetchGifts = () => {
    const { fetchGifts } = this.props;
    const { giftsFetched } = this.state;

    if (!giftsFetched) {
      fetchGifts();
      this.setState({ giftsFetched: true });
    }
  };

  fetchRoomGifts = (roomId) => {
    const { fetchRoomGifts } = this.props;

    return fetchRoomGifts(roomId);
  };

  endThisRoom = () => {
    const { endThisRoom, globalParams } = this.props;

    const { roomId } = this.state;
    //  const { globalParams } = game;

    if (
      globalParams
      && !globalParams.roomClosed
    //  nextTimeStamp < Date.now() + member.offset + 800
    ) {
      return endThisRoom(roomId).then((res) => {
        if (res && res === 'refetch') {
          this.refetchData();
        }
      }).catch((err) => {
        this.setState({
          loading: false,
          error: err,
        });
      });
    }
    return null;
  };

  fetchPlayers = (roomId) => {
    const { fetchPlayers } = this.props;

    return fetchPlayers(roomId)
      .then(() => {
      //  this.setState({ playersLoading: false });
      })
      .catch(err => this.setState({
        loading: false,
        //  playersLoading: false,
        error: err,
      }));
  };

  playCard = ({ selectedCard, myPos }) => {
    const { playACard, globalParams, userSettings, soundOn } = this.props;
    const { roomId, cardPlayed } = this.state;

    //  const { hostname } = window.location;

    //  const { globalParams } = game;

    if (!cardPlayed) {
      if (globalParams && globalParams.gameState !== 'burry') {
        this.setState({ cardPlayed: true });
      }

      if (globalParams && globalParams.gameState === 'burry') {
        if (soundOn) {
          console.log('playSound');
          const playPromise = this.putCardOnTableAudio.play();
          if (playPromise !== undefined) {
            playPromise
              .then(_ => {
                // Automatic playback started!
                // Show playing UI.
                console.log("audio played auto");
              })
              .catch(error => {
                // Auto-play was prevented
                // Show paused UI.
                console.log("playback prevented");
              });
          }
        }
      }

      return playACard(selectedCard, roomId, myPos, globalParams.gameState)
        .then(() => {
          this.setState({ cardPlayed: false });
        })
        .catch((err) => {
          this.setState({
            loading: false,
            error: err,
            cardPlayed: false,
          });
        });
    }

    setTimeout(() => {
      this.setState({ cardPlayed: false });
    }, 400);

    return null;
  };

  chooseGameType = (selectedType) => {
    const { chooseGameType } = this.props;
    const { roomId } = this.state;

    //  const { hostname } = window.location;

    return chooseGameType(selectedType, roomId).catch(err => this.setState({
      loading: false,
      error: err,
    }));
  };

  setLastRound = () => {
    const { lastRoundFunction } = this.props;
    const { roomId } = this.state;

    return lastRoundFunction(roomId).catch(err => this.setState({
      loading: false,
      error: err,
    }));
  };

  quitRound = () => {
    const { quitThisRound } = this.props;
    const { roomId } = this.state;

    this.setState({ quitRoundPressed: false });

    return quitThisRound(roomId)
      .then((res) => {
        if (res.data.status === 'success') {
          this.setState({ quitRoundPressed: true });
        } else {
          this.setState({ quitRoundPressed: false });
        }
      })
      .catch(err => this.setState({
        loading: false,
        error: err,
      }));
  };

  sendGift = (roomId, giftId, comment, players) => {
    const { sendAGift } = this.props;

    return new Promise((resolve) => {
      sendAGift(roomId, giftId, comment, players)
        .then((res) => {
          if (res.data && res.data.data && res.data.data.status === 'success') {
            resolve('success');
          } else if (
            res.data
            && res.data.data
            && res.data.data.status === 'error'
          ) {
            resolve('error');
            this.setState({ errorNotification: res.data.data.message });
          }
        })
        .catch(err => this.setState({
          error: err,
        }));
    });
  };

  resetCloseErrorSubmit = () => {
    this.setState({ closeErrorSubmit: false });
    return '';
  };

  /*  buyMoney = init => {
    const { buyMoney } = this.props;
    const { roomId } = this.state;

    return buyMoney(roomId, init).catch(err => {
      console.log(err);
    });
  };  */

  sendSupportMessage = (data) => {
    const { sendSupportMsg } = this.props;

    return sendSupportMsg(data).catch((err) => {
      console.log(err);
    });
  };

  /*  fetchPreviousRound = () => {
    const { fetchPreviousRound } = this.props;
    const { roomId } = this.state;

    return fetchPreviousRound(roomId).catch(err => {
      console.log(err);
    });
  }; */

  resetErrorNotification = () => {
    this.setState({ errorNotification: '' });
  };

  leaveRoom = (init) => {
    const {
      history,
      leaveThisRoom,
      //  game,
      updateLastLogin,
      removeCurActiveRoom,
      resetStore,
      globalParams,
      lastRoom,
    } = this.props;

    const { roomId, uid } = this.state;

    console.log(this.props);

    console.log('leaveRoom 1', globalParams);

    if (init) {
      leaveThisRoom(roomId, init);
    } else if (globalParams) {
      //  const { globalParams, lastRoom } = game;

      console.log('leaveRoom 2', uid, globalParams.fourthPlayerMissed);

      if (uid && globalParams.fourthPlayerMissed && globalParams.fourthPlayerMissed === uid) {
        updateLastLogin();
        resetStore(lastRoom, roomId);

        console.log('leaveRoom 3');

        removeCurActiveRoom().then(() => {
          console.log('leaveRoom 4');

          // history.push('/');

          this.setState({ date: Date.now() });
        });
      } else if (!globalParams.roomClosed) {
        //  setTimeout(() => {
        console.log('leaveRoom 33333');

        leaveThisRoom(roomId).then((res) => {
          console.log('leaveRoom 3', res);

          if (res.data.status === 'success') {
            if (globalParams.fourPRoom && globalParams.tournamentRoom) {
              updateLastLogin();
              resetStore(lastRoom, roomId);
              removeCurActiveRoom().then(() => {
                history.push('/');

                this.setState({ date: Date.now() });
              });
            } else {
              console.log('leaveRoom 4');

            //  history.push('/');

              console.log('leaveRoom 5');

              this.setState({ date: Date.now() });
            }
          } else if (res.data.status === 'error' && res.data.message === 'notInRoom') {
            console.log('leaveRoom 4');

            updateLastLogin();
            resetStore(lastRoom, roomId);
            removeCurActiveRoom().then(() => {
              console.log('leaveRoom 5');

              history.push('/');

              this.setState({ date: Date.now() });
            });
          }

          return null;
        });
        //  }, 50);
      } else {
        console.log('leaveRoom 3');

        //  setTimeout(() => {
        console.log("leaveRoomthis 3");
        updateLastLogin();
        resetStore(lastRoom, roomId);
        removeCurActiveRoom().then(() => {
          console.log('leaveRoom 4');

          console.log('history.push to leaveRoom', { history: history.location });

          history.push('/');

          console.log('history.push to leaveRoom 2', { history: history.location });

          this.setState({ date: Date.now() });
        });
        //  }, 20);
      }
    }
  }

  closeResultNotif = () => {
    const { closeResultNotif } = this.props;
    const { roomId } = this.state;

    return closeResultNotif(roomId)
      .then(() => {
        this.setState({ quitRoundPressed: false });
      })
      .catch(err => console.log(err));
  }

  playButtonSound = () => {
    const { userSettings, soundOn } = this.props;
    //  const { uid } = this.state;

  //  if (userSettings) {
      if (soundOn) {
        const playPromise = this.buttonClickedAudio.play();
        if (playPromise !== undefined) {
          playPromise
            .then(_ => {
              // Automatic playback started!
              // Show playing UI.
              console.log("audio played auto");
            })
            .catch(error => {
              // Auto-play was prevented
              // Show paused UI.
              console.log("playback prevented");
            });
        }
     }
  //  }
  }

  /*  fetchRoomDataFallback = () => {
    const { fetchRoomDataFallback } = this.props;
    const { roomId } = this.state;

    const { hostname } = window.location;

    if (roomId) {
      fetchRoomDataFallback(roomId, hostname);
    }
  } */

  setShowEndResultModal = (value) => {
    console.log('setShowEndResultModal', value);

    if (value === undefined) value = true;
    this.setState({
      showEndResultModal: value,
    });
  }

  fetchCardsOnTable = async (roomId) => {
    const { fetchCardsOnTable } = this.props;
    this.setState({ cardsOnLoading: true });
    const res = await fetchCardsOnTable(roomId);
    if (res) {
      this.setState({ cardsOnLoading: false });
    }
  }


  toggleCardsOnLoading = () => {
    this.setState({ cardsOnLoading: true });
  }

  render = () => {
    const {
      Layout,
      member,
      //  history,
      userSettings,
      state,
      showNotification,
      blockThisUser,
      unBlockThisUser,
      closeLevelUpNotification,
      setLoadingFunc,
      toggleScoreTableFunc,
      setSupportRead,
      globalParams,
      currentType,
      cards,
      automated,
      myPos,
      isFullscreen,
      isFullscreenEnabled,
      isWebVersion,
      toggleFullscreen,
      //  setProfanitiesNotif,
      profanitiesList,
      profanitiesNotification,
      notificationSettings,
      gameSettings,
      fetchCardsOnTable,
      cardsOnTable,
      toggleNewDesign,
      toggleScreenMode,
      fontMode,
      toggleFont,
      lastRound,
      soundOn,
      designPlatform,
    } = this.props;

    const {
      loading,
      error,
      roomId,
      uid,
      roomClosing,
      closeErrorSubmit,
      quitRoundPressed,
      errorNotification,
      showEndResultModal,
      currentTableLoaded,
      cardsOnLoading,
    } = this.state;

    console.log('showEndResultModal test', showEndResultModal);

    if (globalParams && roomId) {
    //  const { globalParams, players } = game;

      /*  if (
        !playersLoading &&
        member &&
        member.uid &&
        players &&
        players.playerList &&
        !players.playerList[member.uid]
      ) {
        console.log('push away from room');
        console.log(players);
        console.log(member);

        updateLastLogin();
        history.push('/');
      } */

      if (
        !roomClosing
        && globalParams
        && roomId
        && roomId === globalParams.roomId
        && globalParams.closeReason
        && globalParams.roomClosed
      ) {
        this.setState({
          roomClosing: true,
        //  closeReason: globalParams.closeReason,
        });

        let timeoutLength = 5000;

        if (globalParams.closeReason.reason === 'leftRoom' || globalParams.closeReason.reason === 'missedTurn') {
          timeoutLength = 1200;
        }

        this.endTimeoutId = setTimeout(() => {
          console.log('execute timeout', notificationSettings);

          if (notificationSettings && notificationSettings.showAtRoomEnd === false) {
            this.leaveRoom();
          } else {
            this.setState({
              //  closeReason: null,
              showEndResultModal: true,
            });
          }
        }, timeoutLength);
      }

      if (state.isLoading) {
        setLoadingFunc(false);
      }

      /*  const roomProps = {
        // Params
        member: {
          ...member,
          supportChat: null,
          supportChatStatus: null,
        },
        supportChat: member.supportChat,
        supportChatStatus: member.supportChatStatus,
        user: uid,
        roomId,
        error,
        loading,

        globalParams,
        currentType,
        cards,
        automated,
        myPos,
        showEndResultModal,
        quitRoundPressed,
        errorNotification,
        currentTableLoaded,
        userSettings: userSettings || {},

        // Functions
        closeErrorSubmit,
        showNotification,
        playCard: this.playCard,
        chooseGameType: this.chooseGameType,
        endRoom: this.endThisRoom,
        setLastRound: this.setLastRound,
        leaveRoom: this.leaveRoom,
        quitRound: this.quitRound,
        closeResultNotif: this.closeResultNotif,
        resetCloseErrorSubmit: this.resetCloseErrorSubmit,
        sendSupportMessage: this.sendSupportMessage,
        setSupportAsRead: setSupportRead,
        resetErrorNotification: this.resetErrorNotification,
        closeLevelNotification: closeLevelUpNotification,
        sendGift: this.sendGift,
        blockUser: blockThisUser,
        unBlockUser: unBlockThisUser,
        playButtonSound: this.playButtonSound,
        fetchGifts: this.fetchGifts,
        setShowEndResultModal: this.setShowEndResultModal,
        toggleScoreTable: toggleScoreTableFunc,
        isFullscreen,
        isFullscreenEnabled,
        isWebVersion,
        toggleFullscreen,
        setProfanitiesNotif,
        profanitiesList,
        profanitiesNotification,
        notificationSettings,
      };  */

      /*  {...roomProps} */

      return (
        <Layout
          key={roomId}
          member={{
            ...member,
            supportChat: null,
            supportChatStatus: null,
          }}
          supportChat={member.supportChat}
          supportChatStatus={member.supportChatStatus}
          user={uid}
          roomId={roomId}
          error={error}
          loading={loading}

          globalParams={globalParams}
          currentType={currentType}
          cards={cards}
          automated={automated}
          myPos={myPos}
          showEndResultModal={showEndResultModal}
          quitRoundPressed={quitRoundPressed}
          errorNotification={errorNotification}
          currentTableLoaded={currentTableLoaded}
          userSettings={userSettings || {}}
          soundOn={soundOn}

          // Functions
          closeErrorSubmit={closeErrorSubmit}
          showNotification={showNotification}
          playCard={this.playCard}
          chooseGameType={this.chooseGameType}
          endRoom={this.endThisRoom}
          setLastRound={this.setLastRound}
          leaveRoom={this.leaveRoom}
          quitRound={this.quitRound}
          closeResultNotif={this.closeResultNotif}
          resetCloseErrorSubmit={this.resetCloseErrorSubmit}
          sendSupportMessage={this.sendSupportMessage}
          setSupportAsRead={setSupportRead}
          resetErrorNotification={this.resetErrorNotification}
          closeLevelNotification={closeLevelUpNotification}
          sendGift={this.sendGift}
          blockUser={blockThisUser}
          unBlockUser={unBlockThisUser}
          playButtonSound={this.playButtonSound}
          fetchGifts={this.fetchGifts}
          setShowEndResultModal={this.setShowEndResultModal}
          toggleScoreTable={toggleScoreTableFunc}
          isFullscreen={isFullscreen}
          isFullscreenEnabled={isFullscreenEnabled}
          isWebVersion={isWebVersion}
          toggleFullscreen={toggleFullscreen}
        //  setProfanitiesNotif={setProfanitiesNotif}
          profanitiesList={profanitiesList}
          profanitiesNotification={profanitiesNotification}
          notificationSettings={notificationSettings}
          gameSettings={gameSettings}
          cardsOnTable={cardsOnTable}
          fetchCardsOnTable={this.fetchCardsOnTable}
          toggleNewDesign={toggleNewDesign}
          toggleScreenMode={toggleScreenMode}
          cardsOnLoading={cardsOnLoading}
          toggleCardsOnLoading={this.toggleCardsOnLoading}
          fontMode={fontMode}
          toggleFont={toggleFont}
          lastRound={lastRound}
          designPlatform={designPlatform}
        />
      );
    }

    return (
      <Layout
        key={roomId}
        // Params
        member={{
          ...member,
          supportChat: null,
          supportChatStatus: null,
        }}
        supportChat={member.supportChat}
        supportChatStatus={member.supportChatStatus}
        user={uid}
        roomId={roomId}
        error={error}
        loading={loading}
        quitRoundPressed={quitRoundPressed}
        currentTableLoaded={currentTableLoaded}
        userSettings={userSettings || {}}
        soundOn={soundOn}

        // Functions
        closeErrorSubmit={closeErrorSubmit}
        showNotification={showNotification}
        playCard={this.playCard}
        chooseGameType={this.chooseGameType}
        endRoom={this.endThisRoom}
        setLastRound={this.setLastRound}
        leaveRoom={this.leaveRoom}
        quitRound={this.quitRound}
        closeResultNotif={this.closeResultNotif}
        resetCloseErrorSubmit={this.resetCloseErrorSubmit}
        sendSupportMessage={this.sendSupportMessage}
        setSupportAsRead={setSupportRead}
        resetErrorNotification={this.resetErrorNotification}
        closeLevelNotification={closeLevelUpNotification}
        sendGift={this.sendGift}
        blockUser={blockThisUser}
        unBlockUser={unBlockThisUser}
        playButtonSound={this.playButtonSound}
        fetchGifts={this.fetchGifts}
        setShowEndResultModal={this.setShowEndResultModal}
        toggleScoreTable={toggleScoreTableFunc}
        isFullscreen={isFullscreen}
        isFullscreenEnabled={isFullscreenEnabled}
        isWebVersion={isWebVersion}
        toggleFullscreen={toggleFullscreen}
      //  setProfanitiesNotif={setProfanitiesNotif}
        profanitiesList={profanitiesList}
        profanitiesNotification={profanitiesNotification}
        notificationSettings={notificationSettings}
        gameSettings={gameSettings}
        fetchCardsOnTable={this.fetchCardsOnTable}
        toggleNewDesign={toggleNewDesign}
        toggleScreenMode={toggleScreenMode}
        cardsOnLoading={cardsOnLoading}
        toggleCardsOnLoading={this.toggleCardsOnLoading}
        fontMode={fontMode}
        toggleFont={toggleFont}
        lastRound={lastRound}
        designPlatform={designPlatform}
      />
    );
  };
}

const mapStateToProps = state => {
  //  const { match } = ownProps;

  //  const roomId = match && match.params && match.params.id ? match.params.id : null;

  // const notificationSettings = {};

  /*  if (state.userSettings[state.member.uid]) {
      notificationSettings = state.userSettings[state.member.uid].notificationSettings;
    } else if (state.userSettings.default) {
      notificationSettings = state.userSettings.default.notificationSettings;
    } */

  return ({
    //  notificationSettings,
    notificationSettings: state.userSettings ? ((state.userSettings[state.member.uid] && state.userSettings[state.member.uid].notificationSettings) ? (state.userSettings[state.member.uid].notificationSettings) : (state.userSettings.default ? state.userSettings.default.notificationSettings : null)) : {},
    gameSettings: (state.userSettings[state.member.uid] && state.userSettings[state.member.uid].gameSettings) ? (state.userSettings[state.member.uid].gameSettings) : (state.userSettings.default ? state.userSettings.default.gameSettings : null),
    globalParams: state.game.globalParams || {},
    players: state.game.players,
    currentType: state.game.currentType || null,
    cards: state.game.cards || [],
    automated: state.game.automated || null,
    myPos: state.game.myPos || null,
    lastRoom: state.game.lastRoom || null,

    member: {
      uid: state.member.uid,
      name: state.member.name,
      lvl: state.member.level,
      lvlUpNotification: state.member.lvlUpNotification,
      supportChat: state.member.supportChat,
      supportChatStatus: state.member.supportChatStatus,
      offset: state.member.offset,
      activeRoom: state.member.activeRoom,
      role: state.member.role,
    },
    //  userSettings: state.userSettings || {},
    userSettings: state.userSettings[state.member.uid] ? (state.userSettings[state.member.uid]) : (state.userSettings.default ? state.userSettings.default : {}),
    soundOn: state.userSettings.soundOn,
    state: state.state || false,
    cardsOnTable: state.game.cardsOnTable || [],
  });
}
const mapDispatchToProps = {
  fetchCards: getCards,
  fetchAutomatedStatus: getAutomatedStatus,
  playACard: playCard,
  fetchCurrentTable: getCurrentTable,
  fetchGlobalParams: getGlobalParams,
  chooseGameType: selectGameType,
  fetchPlayers: getPlayers,
  //  fetchPlayersWeb: getPlayersWeb,
  fetchPlayerPosition: getPlayerPosition,
  fetchCurrentTurn: getCurrentTurn,
  fetchSittingOut: getSittingOut,
  fetchLargePlayer: getLargePlayer,
  fetchCurrentType: getCurrentType,
  fetchNextTimeStamp: getNextTimeStamp,
  fetchUnlimitedTimerStarted: getUnlimitedTimerStarted,
  endThisRoom: endRoom2,
  leaveThisRoom: leaveRoom,
  lastRoundFunction: setLastRound,
  quitThisRound: quitRound,
  resetStore: resetGameStore,
  closeResultNotif: closeResultNotification,
  sendSupportMsg: sendSupportMessage,
  setSupportRead: setSupportAsRead,
  //  disableTime: disableTimer,
  //  setNextDeal: setNextDealCards,
  //  setMyUserBal: setUserBal,
  sendAGift: sendGift,
  fetchGifts: getGifts,
  fetchRoomGifts: getRoomGifts,
  blockThisUser: blockUser,
  unBlockThisUser: unBlockUser,
  updateLastLogin: updateUserLastLogin,
  closeLevelUpNotification: closeLevelNotification,
  //  fetchPreviousRound: getPreviousRound,
  removeCurActiveRoom: removeActiveRoom,
  setLoadingFunc: setLoading,
  //  fetchRoomDataFallback: roomDataFallback2,
  setRoomId: setCurRoomId,
  fetchInitialData: fetchInitialRoomData,
  refreshDbLastToken: refreshLastToken,
  toggleScoreTableFunc: toggleScoreTable,
  //  debugParam: debugSetParam,
  //  fetchUserInfo: getOtherUserInfo,
  getOffset: getTimeOffset,
  fetchIgnoredPlayers: getIgnoredPlayers,
  fetchProfanitiesList: getProfanitiesList,
  //  setProfanitiesNotif: setProfanitiesNotification,
  fetchCardsOnTable: getCardsOnTable,


  readChatMessages: readChat,
  fetchPoints: getPoints,
  fetchPules: getPules,
  cancelListeners: cancelPointsListeners,
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Zole));
