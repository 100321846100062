import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';

import { connect } from 'react-redux';

import { withRouter } from 'react-router-dom';

import { Firebase } from '../../lib/firebase';

import NetworkDetector from '../components/NetworkDetector/NetworkDetector';

import { AmplitudeService } from '../services';

import config from '../../constants/config';

import {
  checkLoginState,
  draugiemAuth,
  setBonusModal,
  inboxAuth,
  getTimeOffset,
  logout,
  removeUserOnDisconnect,
  initSmartLook,
  failedLoginLog,
  //  getUserData,
  //  initDbs,
  getUserDbStatus,
  getMemberData,
  getUserData3,
  setUserOnlineStatus,
  setUserLoginStatistics,
  getFontMode,
} from '../../actions/member';


import { getUsersSetting } from '../../actions/userSettings';
import { getRoomDbStatus } from '../../actions/game';
import { clearAdminReturnToken } from '../../actions/users';

import {
  FONT_MODE, SCREEN_MODE,
} from '../../constants/constants';
import { getDesignPlatforms } from '../../actions/admin';


class Auth extends React.Component {
  static propTypes = {
    t: PropTypes.func.isRequired,
  //  i18n: PropTypes.shape({
  //    language: PropTypes.string,
  //  }).isRequired,
    checkLogin: PropTypes.func.isRequired,
    removeOnDisconnect: PropTypes.func.isRequired,
    setUserOnline: PropTypes.func.isRequired,
    getOffset: PropTypes.func.isRequired,
    setBonusModalFunc: PropTypes.func.isRequired,
    //  draugiemAuthFunc: PropTypes.func.isRequired,
    member: PropTypes.shape({
      uid: PropTypes.string,
      name: PropTypes.string,
      email: PropTypes.string,
      screenMode: PropTypes.string,
      newDesign: PropTypes.bool,
    }),
    signOut: PropTypes.func.isRequired,
    newDesign: PropTypes.bool,
    fontMode: PropTypes.string,
    history: PropTypes.shape({
      push: PropTypes.func.isRequired,
    }).isRequired,
    fetchUserDbStatus: PropTypes.func.isRequired,
    fetchRoomDbStatus: PropTypes.func.isRequired,
    fetchMemberData: PropTypes.func.isRequired,
    setUserLoginStats: PropTypes.func.isRequired,
    draugiemAuthFunc: PropTypes.func.isRequired,
    toggleNewDesign: PropTypes.func.isRequired,
    authChecked: PropTypes.func.isRequired,
    fetchDesignPlatforms: PropTypes.func.isRequired,
    fetchFontMode: PropTypes.func.isRequired,
    fetchUsersSetting: PropTypes.func.isRequired,
  }

  static defaultProps = {
    member: {},
    newDesign: false,
    fontMode: FONT_MODE.normal,
  }

  constructor(props) {
    super(props);
    this.state = {
      // lastUid: null,
    };

    this.checkLoginState = this.checkLoginState.bind(this);
  }

  componentWillMount() {
  //  const { initializeDbs } = this.props;
    // initSmartLookApp();

  //  initializeDbs();
  }

  componentDidMount = async () => {
    const {
      i18n,
      history,
      removeOnDisconnect,
      getOffset,
      fetchUserDbStatus,
      fetchRoomDbStatus,
      fetchMemberData,
      setUserOnline,
      setUserLoginStats,
      setBonusModalFunc,
      newDesign,
      // fontMode,
      draugiemAuthFunc,
      inboxAuthFunc,
      toggleNewDesign,
      authChecked,
      fetchFontMode,
      fetchUsersSetting,
      fetchUserData3,
      claimHolidayFunc,
      fetchDesignPlatforms,
      clearAdminReturnTokenFunc,
      screenMode,
    } = this.props;

    //  removeOnDisconnect();

    let language;

    if (i18n) {
       language = i18n.language;
    }

    await AmplitudeService.init();

    fetchMemberData().then((res) => {
      console.log('fetchMemberData res', res);
    });

    Firebase.auth().onAuthStateChanged(async (user) => {
      if (user) {
        let fontMode2;

        let latestLanguage;

        if (i18n) {
          latestLanguage = i18n.language;
        }

        try {
          fontMode2 = localStorage.getItem('fontMode') ? JSON.parse(localStorage.getItem('fontMode')) : FONT_MODE.normal; //x
        } catch (err) {
          fontMode2 = FONT_MODE.normal;
          // console.log(err);
        }

        console.log('onAuthStateChanged fontMode2', fontMode2, newDesign);

        removeOnDisconnect();
        setUserOnline();

        await fetchFontMode();
        await fetchUsersSetting();

        console.log('call fetchMember 1111');

      //  await fetchUserData3().then((res) => {
      //    console.log('fetchUserData3 res', res);
      //  });

        console.log('call fetchMember 2222');

        const { member } = this.props;

        authChecked();
        console.log('onAuthStateChanged screenMode member', member.screenMode, screenMode, member);

        /* let screenMode;

        try {
          screenMode = (member && member.screenMode) ? member.screenMode : (localStorage.getItem('screenMode') ? JSON.parse(localStorage.getItem('screenMode')) : SCREEN_MODE.normal); //x
        } catch (err) {
          screenMode = (member && member.screenMode) ? member.screenMode : SCREEN_MODE.normal;
          console.log(err);
        } */

        try {
          if ((member && member.newDesign) || localStorage.getItem('newDesign')) { //x // || localStorage.getItem('newDesign') this part fixes new design switching to old design when registering
            toggleNewDesign(true, true);
          } else {
            toggleNewDesign(false, true);
          }
        } catch (err) {
          if ((member && member.newDesign)) {
            toggleNewDesign(true, true);
          } else {
            toggleNewDesign(false, true);
          }
          console.log(err);
        }
        fetchDesignPlatforms();
        console.log('onAuthStateChanged screenMode', screenMode, latestLanguage);

        setUserLoginStats(true, newDesign, latestLanguage, fontMode2, screenMode, null);

        if (user.uid) {
          try {
            localStorage.setItem('latestUid', JSON.stringify(user.uid)); //x
          } catch (err) {
            console.log(err);
          }
          AmplitudeService.setUserId(user.uid);
        }
      } else {
        console.log('call clearAdminReturnToken');
        try {
          clearAdminReturnTokenFunc();
        } catch (err) {
          console.log(err);
        }

        try {
          localStorage.setItem('latestUid', null); //x
        } catch (err) {
          console.log(err);
        }
      }
    });

    /* setTimeout(() => {
      const { member } = this.props;
      if (member && member.smartLookStatus && member.uid && member.name) {
        try {
          smartlookClient.consentAPI('Here goes consent text from your website.');
          smartlookClient.identify(member.uid, {
            name: member.name,
            email: member.email,
            provider: member.socProvider,
          });
        } catch (err) {
          console.log(err);
        }
      }
    }, 1500);
    */

    getOffset();
    fetchUserDbStatus();
    fetchRoomDbStatus();
    //  fetchMemberData();
    //  fetchUserData3();


    // Reset viewed events on start
    try {
      localStorage.removeItem('checkedEvents');
      localStorage.removeItem('checkedDiscounts');
    } catch (err) {
    }

    const { hostname, search, pathname } = window.location;

    if (!config.isInAppFrame()) {
      const unsb = Firebase.auth().onAuthStateChanged((user) => {
        console.log('onAuthStateChanged', { user, pathname });
        unsb();

        try {
          if (user && user.uid) {
            localStorage.setItem('latestUid', JSON.stringify(user.uid)); //x
          } else {
            localStorage.setItem('latestUid', null); //x
          }
        } catch (err) {
          console.log(err);
        }

        if (((!user && pathname !== '/email-verify') || (!pathname.includes('/landing') && user && !user.emailVerified && user.providerData && user.providerData[0] && user.providerData[0].providerId === 'password'))
          && !pathname.includes('dra-redirect') && !pathname.includes('terms-of-use') && !pathname.includes('reset-password') && !pathname.includes('login') && !pathname.includes('forgot-password') && !pathname.includes('sign-up')
          && !pathname.includes('home') && !pathname.includes('blog') && !pathname.includes('game-rule') && !pathname.includes('version-history') && !pathname.includes('terms-of-use')
          && !pathname.includes('contacts')) {
          console.log('push to /landing===');
          //  console.log('history.push');
          history.push('/landing');

        // this.setState({ date: Date.now() });
        } else if (user && !pathname.includes('admin') && !pathname.includes('zole/') && !pathname.includes('terms-of-use') && (user.providerData && user.providerData[0] && user.providerData[0].providerId !== 'password' && pathname.includes('update-profile'))) {
          console.log('push to /');
          console.log('history.push');
          history.push('/');

          // this.setState({ date: Date.now() });
        }
      });

      return;
    }

    console.log('Auth hostname', hostname, config.isInAppFrame());

    if (hostname === 'dra.spelezoli.lv' || hostname === 'dra.dev.spelezoli.lv') {
      let authCodeText;
      let devMode;
      let chestRedirect;
      let adventBonusRedirect;

      if (hostname === 'dra.dev.spelezoli.lv') {
        devMode = true;
      }

      try {
        const urlParams = new URLSearchParams(search);

        authCodeText = urlParams.get('dr_auth_code');
        chestRedirect = urlParams.get('chest_redirect');
        adventBonusRedirect = urlParams.get('advent_bonus_redirect');
      } catch (err) {
        authCodeText = this.getParameterByName('dr_auth_code');
        chestRedirect = this.getParameterByName('chest_redirect');
        adventBonusRedirect = this.getParameterByName('advent_bonus_redirect');
      }

      if (authCodeText && hostname.includes('dra')) {
        draugiemAuthFunc(authCodeText, devMode, language).then((res) => {
          if (chestRedirect) {
            setBonusModalFunc('chest');
          }

          if (adventBonusRedirect) {
            setBonusModalFunc('advent');
          }

        //  fetchUserData();
        });
      } else if (hostname.includes('dra')) {
        setTimeout(() => {
          let authCodeText2;

          const search2 = window.location.search;

          try {
            const urlParams = new URLSearchParams(search2);

            authCodeText2 = urlParams.get('dr_auth_code');
            chestRedirect = urlParams.get('chest_redirect');
            adventBonusRedirect = urlParams.get('advent_bonus_redirect');
          } catch (err) {
            authCodeText2 = this.getParameterByName('dr_auth_code');
            chestRedirect = this.getParameterByName('chest_redirect');
            adventBonusRedirect = this.getParameterByName('advent_bonus_redirect');
          }

          if (authCodeText2) {
            draugiemAuthFunc(authCodeText2, devMode, language).then((res) => {
              if (chestRedirect) {
                setBonusModalFunc('chest').catch((err) => {
                  console.log(err);
                });
              }

              if (adventBonusRedirect) {
                setBonusModalFunc('advent').catch((err) => {
                  console.log(err);
                });
              }
            });
          }
        }, 1500);
      }
    } else if (hostname === 'fb.spelezoli.lv' || hostname === 'fb.dev.spelezoli.lv') {
      if (window.FB) {
        window.FB.getLoginStatus((response) => {
          this.checkLoginState(response, language);
        });
      } else {
        document.addEventListener('FBObjectReady', this.initializeFacebookLogin);
      }
    } if (hostname === 'inbox.spelezoli.lv' || hostname === 'inbox.dev.spelezoli.lv') {
      let uidText;
      let devMode;
      // let language;

      if (hostname === 'inbox.dev.spelezoli.lv') {
        devMode = true;
      }

      try {
        const urlParams = new URLSearchParams(search);
        uidText = urlParams.get('uid');
        language = urlParams.get('language');
      } catch (err) {
        uidText = this.getParameterByName('uid');
        language = this.getParameterByName('language');
      }

      if (uidText && hostname.includes('inbox')) {
        console.log('call inboxAuth');
        inboxAuthFunc(uidText, devMode, language).then(() => {
          console.log('inboxAuth res');
        });
      } else if (hostname.includes('inbox')) {
        setTimeout(() => {
          let uidText2;

          const search2 = window.location.search;

          try {
            const urlParams = new URLSearchParams(search2);
            uidText2 = urlParams.get('uid');
            language = urlParams.get('language');
          } catch (err) {
            uidText2 = this.getParameterByName('uid');
            language = this.getParameterByName('language');
          }

          if (uidText2) {
            inboxAuthFunc(uidText2, devMode, language).then(() => {
            });
          }
        }, 1500);
      }
    }
  }

  componentWillUnmount() {
  //  console.log('Auth componentWillUnmount');
  //  const { signOut } = this.props;
  //  signOut();
  }

  getParameterByName = (name, url) => {
    let url2;
    if (!url) {
      url2 = window.location.href;
    } else {
      url2 = url;
    }

    const name2 = name.replace(/[\[\]]/g, '\\$&');
    const regex = new RegExp(`[?&]${name2}(=([^&#]*)|&|#|$)`);

    const results = regex.exec(url2);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
  }


  initializeFacebookLogin = () => {
    const { i18n } = this.props;
    let language;

    if (i18n) {
      language = i18n.language;
      // ({ language } = i18n);
    }

    window.FB.getLoginStatus((response) => {
      this.checkLoginState(response, language);
    });
  }


  checkLoginState(event, language) {
    const { checkLogin, signOut, history } = this.props;

    if (event.status === 'connected') {
      window.FB.api('/me', { fields: 'email' }, (response2) => {
        if (response2 && response2.email) {
          checkLogin(event, response2.email, language, history).then(() => {
          //  fetchUserData();
          });
        //  console.log('isloggedin');
        } else {
          checkLogin(event, null, language, history).then(() => {
          //  fetchUserData();
          });
        }
      });
    } else if (event.status === 'not_authorized') {
      signOut().then(() => {
        window.FB.login((response2) => {
          window.FB.api('/me', { fields: 'email' }, (response3) => {
            if (response3 && response3.email) {
              if (response2.authResponse) {
                checkLogin(response2, response3.email, language, history);
              } else {
                checkLogin(response2, response3.email, language, history);
              }
            } else {
              checkLogin(response2, null, language, history);
            }
          });
        }, { scope: 'email' });
      });
    } else {
      signOut().then(() => {
        window.FB.login((response2) => {
          window.FB.api('/me', { fields: 'email' }, (response3) => {
            if (response3 && response3.email) {
              if (response2.authResponse) {
                checkLogin(response2, response3.email, language, history);
              } else {
                checkLogin(response2, response3.email, language, history);
              }
            } else {
              checkLogin(response2, null, language, history);
            }
          });
        }, { scope: 'email' });
      });
    }
  }

  render() {
    const { newDesign, fontMode, member, t } = this.props;
    return (
      <Fragment>
        <NetworkDetector fontMode={fontMode} t={t} />
        {/*  <ReactInternetSpeedMeter
            txtSubHeading="Internet is too slow"
            outputType="alert"
            customClassName={null}
            txtMainHeading="Opps..."
            pingInterval={5000} // milliseconds
            thresholdUnit='megabyte' // "byte" , "kilobyte", "megabyte"
            threshold={10}
            imageUrl="https://dra.spelezoli.lv/static/media/gift.4928e920.svg"
            downloadSize="3008"  //bytes
            callbackFunctionOnNetworkDown={(speed)=>console.log(`Internet speed is ${speed}`)}
          //  callbackFunctionOnNetworkTest={(speed)=>console.log(`Internet speed is ${speed}`)}
          /> */}
      </Fragment>
    );
  }
}

const mapStateToProps = state => ({
  member: state.member || {},
  designPlatforms: state.admin.designPlatforms || {},
});

const mapDispatchToProps = {
  checkLogin: checkLoginState,
  getOffset: getTimeOffset,
  signOut: logout,
  removeOnDisconnect: removeUserOnDisconnect,
  initSmartLookApp: initSmartLook,
  setFailedLoginLog: failedLoginLog,
  //  fetchUserData: getUserData,
  //  initializeDbs: initDbs,
  fetchRoomDbStatus: getRoomDbStatus,
  fetchUserDbStatus: getUserDbStatus,
  fetchMemberData: getMemberData,
  fetchUserData3: getUserData3,
  fetchDesignPlatforms: getDesignPlatforms,
  setUserOnline: setUserOnlineStatus,
  setBonusModalFunc: setBonusModal,
  setUserLoginStats: setUserLoginStatistics,
  draugiemAuthFunc: draugiemAuth,
  inboxAuthFunc: inboxAuth,
  fetchFontMode: getFontMode,
  fetchUsersSetting: getUsersSetting,
  clearAdminReturnTokenFunc: clearAdminReturnToken,
};

export default withTranslation('common')(withRouter(connect(mapStateToProps, mapDispatchToProps)(Auth)));
