import moment from 'moment';
import { getBaseOs } from 'react-native-device-info';

import {
  isAndroid,
  isIOS,
  isIOS13,
  osName,
  osVersion,
} from 'react-device-detect';

import { size } from 'lodash';
import { AppState } from 'react-native';
import {
  Firebase, FirebaseRef, RoomsPublicRef, RoomsRef, LeaderboardRef, AdminLogsRef,
} from '../lib/firebase';

// import ReactGA from 'react-ga';

import { Event } from '../web/components/Tracking';
import { clearLeaveRoom } from '../native/redux/actions/room';
import { JOINED_ROOM } from './index';

export const listenToRoomChat = (roomId, onResult) => () => {
  FirebaseRef.child(`chat/${roomId}/status`)
    .on('value', (snap) => {
      const UID = (
        FirebaseRef
        && Firebase
        && Firebase.auth()
        && Firebase.auth().currentUser
        && Firebase.auth().currentUser.uid
      ) ? Firebase.auth().currentUser.uid : null;
      if (snap.val() && !snap.val()[UID]) {
        onResult();
      }
    });
};

export function getRooms() {
  if (Firebase === null) return () => new Promise(resolve => resolve());
  return dispatch => new Promise((resolve) => {
    dispatch({ type: 'ROOMS_REPLACE', data: {} });
    RoomsPublicRef().child('roomsPubInf').off();
    RoomsPublicRef().child('roomsPubInfIds').off();
    RoomsPublicRef().child('roomsPubInf')
      .orderByChild('filled')
      .equalTo(null)
      .once('value', (snapshot3) => {
        const data = snapshot3.val() || {};
        dispatch({ type: 'ROOMS_REPLACE', data: { ...data } }); //, key: snapshot3

        RoomsPublicRef().child('roomsPubInf')
          .orderByChild('filled')
          .equalTo(null)
          .on('child_changed', (snapshot2) => {
            const data2 = snapshot2.val() || {};
            const { key } = snapshot2;

            if (data2 && data2.playersList) {
              dispatch({ type: 'ROOMS_REPLACE_CHANGE', data: { ...data2, key }, key });
            } else {
              dispatch({ type: 'ROOMS_REPLACE_REMOVE', data: null, key });
            }
          });

        RoomsPublicRef().child('roomsPubInf')
          .orderByChild('filled')
          .equalTo(null)
          .on('child_removed', (snapshot2) => {
            const { key } = snapshot2;
            dispatch({ type: 'ROOMS_REPLACE_REMOVE', data: null, key });
          });

        RoomsPublicRef().child('roomsPubInf')
          .orderByChild('filled')
          .equalTo(null)
          .on('child_added', (snapshot2) => {
            const data2 = snapshot2.val() || {};
            const { key } = snapshot2;
            dispatch({ type: 'ROOMS_REPLACE_CHANGE', data: { ...data2, key }, key });
          });


        return resolve();
      }, (err) => { console.log(err); });
  }).catch((err) => {
    console.log(err);
    // throw err.message;
  });
}


const _roomFilled = (room) => {
  if (room.playersList && room.globalParams) {
    if (!room.globalParams.fourPRoom
      && room.playersList.player1 && room.playersList.player1.uid
      && room.playersList.player2 && room.playersList.player2.uid
      && room.playersList.player3 && room.playersList.player3.uid) {
      return true;
    } if (room.globalParams.fourPRoom
      && room.playersList.player1 && room.playersList.player1.uid
      && room.playersList.player2 && room.playersList.player2.uid
      && room.playersList.player3 && room.playersList.player3.uid
      && room.playersList.player4 && room.playersList.player4.uid) {
      return true;
    }
    return false;
  }
  return false;
};


export function getRoomsRework() {
  console.log('getRoomsRework');
  if (Firebase === null) return () => new Promise(resolve => resolve());

  return dispatch => new Promise((resolve) => {
    console.log('getRoomsRework 2');
    dispatch({ type: 'ROOMS_REPLACE', data: {} });

    console.log('getRoomsRework 3');
    RoomsPublicRef().child('roomsPubInf').off();
    RoomsPublicRef().child('roomsPubInfIds').off();
    RoomsPublicRef().child('roomsPubInf')
      .orderByChild('filled')
      .equalTo(null)
      .once('value', async (snapshot3) => {
        const data = snapshot3.val() || {};

        console.log('getRoomsRework data', data);

        dispatch({ type: 'ROOMS_REPLACE', data: { ...data } }); // , key: snapshot3

        await RoomsPublicRef().child('roomsPubInf')
          .orderByChild('filled')
          .equalTo(null)
          .on('child_changed', (snapshot2) => {
            const data2 = snapshot2.val() || {};
            const { key } = snapshot2;

            //  console.log('roomsPubInf child_changed', { data2 });

            if (data2 && data2.playersList) {
              /*  if (_roomFilled(data2)) {
                  console.log('is filled room');
  
                  setTimeout(() => {
                    dispatch({ type: 'ROOMS_REPLACE_REMOVE', data: null, key });
                  }, 5000);
  
                  dispatch({ type: 'ROOMS_REPLACE_SET_FILLED', key });
                } else { */
              //  console.log('is NOT filled room');

              dispatch({ type: 'ROOMS_REPLACE_CHANGE', data: { ...data2, key }, key });
              //  }
              //  dispatch({ type: 'ROOMS_REPLACE_CHANGE', data: { ...data2, key }, key });
            } else {
              //  dispatch({ type: 'ROOMS_REPLACE_REMOVE', data: null, key });

              try {
                setTimeout(() => {
                  console.log('getRoomsRework timeout');
                  dispatch({ type: 'ROOMS_REPLACE_REMOVE', data: null, key });
                }, 3000);
              } catch (err) {
                console.log('err', err);
              }

              dispatch({ type: 'ROOMS_REPLACE_SET_FILLED', key });
            }
          });

        await RoomsPublicRef().child('roomsPubInf')
          .orderByChild('filled')
          .equalTo(null)
          .on('child_removed', (snapshot2) => {
            const { key } = snapshot2;

            //  console.log('roomsPubInf child_removed', { key });

            try {
              setTimeout(() => {
                dispatch({ type: 'ROOMS_REPLACE_REMOVE', data: null, key });
              }, 3000);
            } catch (err) {
              console.log('err', err);
            }

            dispatch({ type: 'ROOMS_REPLACE_SET_FILLED', key });

            //  dispatch({ type: 'ROOMS_REPLACE_REMOVE', data: null, key });
          });

        await RoomsPublicRef().child('roomsPubInf')
          .orderByChild('filled')
          .equalTo(null)
          .on('child_added', (snapshot2) => {
            const data2 = snapshot2.val() || {};
            const { key } = snapshot2;

            dispatch({ type: 'ROOMS_REPLACE_CHANGE', data: { ...data2, key }, key });
          });

        return resolve();
      }, (err) => {
        console.log('getRoomsRework err');
        console.log(err);
      });
  }).catch((err) => {
    console.log('getRoomsRework err 2');
    console.log(err);
    // throw err.message;
  });
}


export function changeRoomsFilter() {
  if (Firebase === null) return () => new Promise(resolve => resolve());

  return dispatch => new Promise(resolve => resolve());
}


export function cancelRoomsListeners() {
  if (Firebase === null) return () => new Promise(resolve => resolve());

  return dispatch => new Promise((resolve) => {
    RoomsPublicRef().child('roomsPubInf').off();
    RoomsPublicRef().child('roomsPubInfIds').off();

    return resolve(dispatch({ type: 'RESET_ROOMS' }));
  });
}


export function getMyRooms() {
  if (Firebase === null) return () => new Promise(resolve => resolve());

  return () => new Promise((resolve) => {
    const UID = (
      FirebaseRef
      && Firebase
      && Firebase.auth()
      && Firebase.auth().currentUser
      && Firebase.auth().currentUser.uid
    ) ? Firebase.auth().currentUser.uid : null;

    if (UID) {
      /*  FirebaseRef.child(`users/${UID}/joinedRooms`)
          .on('value', (snapshot) => {
            const joinedRooms = snapshot.val() || {};
  
            let joinedRoomKey;
            Object.keys(joinedRooms).map((key, index) => {
              if (index === 0) {
                joinedRoomKey = key;
              }
              return null;
            });
  
            if (joinedRoomKey) {
              RoomsPublicRef().child(`roomsPubInf/${joinedRoomKey}`)
                .once('value', (snapshot2) => {
                  const data2 = snapshot2.val() || {};
                  const { key } = snapshot2;
  
                  if (data2 && data2.globalParams) {
                    dispatch({ type: 'ROOMS_REPLACE_CHANGE', data: data2, key });
                  //  dispatch({ type: 'MY_ROOMS_REPLACE_CHANGE', data: data2, key });
                  } else {
                    dispatch({ type: 'ROOMS_REPLACE_REMOVE', data: null, key });
                  //  dispatch({ type: 'MY_ROOMS_REPLACE_REMOVE', data: null, key });
                  }
                });
            } else {
            //  dispatch({ type: 'RESET_MY_ROOMS' });
            }
  
            return resolve();
          }); */
      return resolve();
    }
    return resolve();
  }).catch((err) => {
    console.log('err', err);
    // throw err.message;
  });
}

export function resetRoomsList() {
  if (Firebase === null) return () => new Promise(resolve => resolve());

  return dispatch => new Promise(resolve => resolve(dispatch({ type: 'RESET_ROOMS' })));
}


export const joinRoomWithWrite = (roomId, password, fourPRoom) => dispatch => new Promise(resolve => getBaseOs().then((baseOs) => {
  let env;

  if (baseOs === 'Windows' || (window && window.document)) {
    env = 'web';
  } else if (isAndroid) {
    env = 'android';
  } else if (isIOS || isIOS13) {
    env = 'ios';
  } else {
    env = 'android';
  }

  const osNameVal = osName;
  const osVersionVal = osVersion;

  if (osNameVal === 'Android') {
    env = 'android';
  } else if (osNameVal === 'iOS') {
    env = 'ios';
  } else if (osNameVal === 'Windows' || osNameVal === 'Linux') {
    env = 'web';
  }

  /*
  else if (baseOs && baseOs === 'Android') {
    env = 'android';
  } else {
    env = 'ios';
  } */

  const UID = (
    FirebaseRef
    && Firebase
    && Firebase.auth()
    && Firebase.auth().currentUser
    && Firebase.auth().currentUser.uid
  ) ? Firebase.auth().currentUser.uid : null;


  FirebaseRef.child(`joinRoomRequest/${UID}`).set({
    roomId,
    userPassword: password,
    env,
    osName: osNameVal || null,
    osVersion: osVersionVal || null,
  });
  Event('Room', 'Join room', null);
  return resolve(
    dispatch({
      type: JOINED_ROOM, data: { key: roomId }, roomId, password,
    }),
  );
}).catch((err) => {
  resolve({ code: err.code });
}));


export function resetRoomError() {
  console.log('resetRoomError');
  if (Firebase === null) return () => new Promise(resolve => resolve());

  const UID = (
    FirebaseRef
    && Firebase
    && Firebase.auth()
    && Firebase.auth().currentUser
    && Firebase.auth().currentUser.uid
  ) ? Firebase.auth().currentUser.uid : null;

  if (UID) {
    FirebaseRef.child(`users/${UID}/joinRoomError`).remove();
  }

  return () => new Promise(resolve => resolve());
}

export function joinRoom(roomId, password, fourPRoom) {
  //  console.log('joinRoom', { roomId, password, fourPRoom });
  if (Firebase === null) return () => new Promise(resolve => resolve());

  const UID = (
    FirebaseRef
    && Firebase
    && Firebase.auth()
    && Firebase.auth().currentUser
    && Firebase.auth().currentUser.uid
  ) ? Firebase.auth().currentUser.uid : null;

  //  console.log('UID', { UID });

  return dispatch => new Promise(resolve => getBaseOs().then((baseOs) => {
    /* let env;
    if (baseOs === 'Windows' || (window && window.document)) {
      env = 'web';
    } else if (baseOs && baseOs === 'Android') {
      env = 'android';
    } else {
      env = 'ios';
    } */

    let env;
    if (baseOs === 'Windows' || (window && window.document)) {
      env = 'web';
    } else if (isAndroid) {
      env = 'android';
    } else if (isIOS || isIOS13) {
      env = 'ios';
    } else {
      env = 'android';
    }

    const osNameVal = osName;
    const osVersionVal = osVersion;

    if (osNameVal === 'Android') {
      env = 'android';
    } else if (osNameVal === 'iOS') {
      env = 'ios';
    } else if (osNameVal === 'Windows' || osNameVal === 'Linux') {
      env = 'web';
    }

    //  env = 'mobile';

    //  const { rooms, member } = getState();

    //  if (member && !member.joinedRooms) {
    //  Firebase.auth().onAuthStateChanged((user) => {
    //    if (user) {

    return FirebaseRef.child(`joinRoomRequest/${UID}`).set({
      roomId,
      userPassword: password,
      env,
      osName: osNameVal || null,
      osVersion: osVersionVal || null,
    }).then(result => resolve(dispatch({
      type: 'JOINED_ROOM', data: { ...result, key: roomId }, roomId, password,
    }))).catch((err) => {
      console.log(err, 'actionError');
      return resolve();
    });

    /*  let joinRoomFunction;

    if (fourPRoom) {
      joinRoomFunction = Firebase.app().functions().httpsCallable('join4PlayerRoom');
    } else {
      joinRoomFunction = Firebase.app().functions().httpsCallable('joinRoom');
    }

    console.log('we are joining room now')
    console.log('we are joining room now')
    console.log('we are joining room now')
    console.log('we are joining room now')
    console.log('we are joining room now')
    console.log('we are joining room now')
    console.log('we are joining room now')
    console.log('we are joining room now')
    console.log('we are joining room now')

    joinRoomFunction({
      roomId,
      userPassword: password,
      env,
    }).then((result) => {
      Event('Room', 'Join room', null);

      //  if (result && result.status === 'success' && result.isStarted) {
      //    dispatch({ type: 'SET_USER_ACTIVE_ROOM', data: roomId });
      //  }

      return resolve(
        dispatch({
          type: 'JOINED_ROOM', data: { ...result, key: roomId }, roomId, password,
        }),
      );
    }).catch((err) => {
      console.log('join room error', { err });
      console.log('join room error', { err });
      console.log('join room error', { err });
      console.log('join room error', { err });
      console.log('join room error', { err });
      console.log('join room error', { err });
      console.log('join room error', { err });
      console.log('join room error', { err });
      console.log('join room error', { err });
      console.log('join room error', { err });
      console.log('join room error', { err });
      console.log('join room error', { err });
      console.log('join room error', { err });
      return resolve();
    });
    */


    //    }
    //  })
    //  }
  }).catch(err => resolve({ code: err.code })));
}


export function createRoom(parasta, G, atra, pro, bet, maza, privateRoom, minGames, fourPRoom, speed, hasAutoMergeRooms) {
  console.log('createRoom', { parasta, G, atra, pro, bet, maza, privateRoom, minGames, fourPRoom, speed, hasAutoMergeRooms });

  if (Firebase === null) return () => new Promise(resolve => resolve());

  const UID = (
    FirebaseRef
    && Firebase
    && Firebase.auth()
    && Firebase.auth().currentUser
    && Firebase.auth().currentUser.uid
  ) ? Firebase.auth().currentUser.uid : null;

  const emailVerified = (
    FirebaseRef
    && Firebase
    && Firebase.auth()
    && Firebase.auth().currentUser
    && Firebase.auth().currentUser.emailVerified
  ) ? Firebase.auth().currentUser.emailVerified : null;

  console.log('emailVerified', emailVerified);

  //  console.log('createRoom', {
  //    parasta, G, atra, pro, bet, maza, privateRoom, minGames, fourPRoom, speed,
  //  });

  // create4PlayerRoom
  let createRoomFunction;

  if (fourPRoom) {
    createRoomFunction = Firebase.app().functions().httpsCallable('create4PlayerRoom');
  } else {
    createRoomFunction = Firebase.app().functions().httpsCallable('createRoom');
  }

  //  const createRoomFunction = Firebase.app().functions().httpsCallable('createRoom');

  return (dispatch, getState) => new Promise(resolve => getBaseOs().then(async (baseOs) => {
    /*let env;
    if (baseOs === 'Windows' || (window && window.document)) {
      env = 'web';
    } else if (baseOs && baseOs === 'Android') {
      env = 'android';
    } else {
      env = 'ios';
    } */

    let env;
    if (baseOs === 'Windows' || (window && window.document)) {
      env = 'web';
    } else if (isAndroid) {
      env = 'android';
    } else if (isIOS || isIOS13) {
      env = 'ios';
    } else {
      env = 'android';
    }

    const osNameVal = osName;
    const osVersionVal = osVersion;

    if (osNameVal === 'Android') {
      env = 'android';
    } else if (osNameVal === 'iOS') {
      env = 'ios';
    } else if (osNameVal === 'Windows' || osNameVal === 'Linux') {
      env = 'web';
    }
    //  env = 'mobile';

    const { rooms } = getState();

    console.log(rooms.isJoiningRoom);

    if (!rooms.isJoiningRoom) {
      dispatch({ type: 'IS_JOINING_ROOM', data: true });

      /*  createRoomFunction({
        parasta,
        G,
        atra: speed === 'atra',
        lightning: speed === 'lightning',
        pro,
        bet,
        maza,
        privateRoom,
        minGames,
        env,
      }).then((result) => { */

      return FirebaseRef.child(`createRoomRequest/${UID}`).set({
        parasta: parasta || null,
        G: G || null,
        atra: speed === 'atra',
        lightning: speed === 'lightning',
        unlimited: speed === 'unlimited',
        pro,
        bet,
        maza: maza || null,
        privateRoom: privateRoom || null,
        minGames: minGames || null,
        env: env || null,
        osName: osNameVal || null,
        osVersion: osVersionVal || null,
        fourPRoom: fourPRoom || null,
        hasAutoMergeRooms: hasAutoMergeRooms || false, // Disable for prod while not ready
      }).then((result) => {
        Event('Room', 'Create room', null);

        console.log('created room', result);

        //  if (result && result.data && result.data.status === 'error' && result.data.error === 'email not verified') {
        if (!emailVerified) {
          return Firebase.auth().currentUser.getIdToken(true).then(() =>
            /*  createRoomFunction({
                parasta,
                G,
                atra: speed === 'atra',
                lightning: speed === 'lightning',
                pro,
                bet,
                maza,
                privateRoom,
                minGames,
                env,
              }).then((result2) => { */

            FirebaseRef.child(`createRoomRequest/${UID}`).set({
              parasta: parasta || null,
              G: G || null,
              atra: speed === 'atra',
              lightning: speed === 'lightning',
              unlimited: speed === 'unlimited',
              pro,
              bet,
              maza: maza || null,
              privateRoom: privateRoom || null,
              minGames: minGames || null,
              env: env || null,
              fourPRoom: fourPRoom || null,
              hasAutoMergeRooms: hasAutoMergeRooms || false, // Disable for prod while not ready
            }).then((result2) => {
              dispatch({ type: 'IS_JOINING_ROOM', data: false });

              return resolve(dispatch({ type: 'CREATED_ROOM', data: result2 }));
            }).catch(() => resolve(dispatch({ type: 'IS_JOINING_ROOM', data: false })))).catch(() => resolve(dispatch({ type: 'IS_JOINING_ROOM', data: false })));
        }
        dispatch({ type: 'IS_JOINING_ROOM', data: false });

        return resolve(dispatch({ type: 'CREATED_ROOM', data: result }));
      }).catch((err) => {
        console.log('err', err);
        dispatch({ type: 'IS_JOINING_ROOM', data: false });
      });
    }
    setTimeout(() => {
      dispatch({ type: 'IS_JOINING_ROOM', data: false });
    }, 1500);
  }));
}

export function join4PlayerRoom(roomId, position, password) {
  if (Firebase === null) return () => new Promise(resolve => resolve());

  const UID = (
    FirebaseRef
    && Firebase
    && Firebase.auth()
    && Firebase.auth().currentUser
    && Firebase.auth().currentUser.uid
  ) ? Firebase.auth().currentUser.uid : null;

  return dispatch => new Promise(resolve => getBaseOs().then((baseOs) => {
    /* let env;
    if (baseOs === 'Windows' || (window && window.document)) {
      env = 'web';
    } else if (baseOs && baseOs === 'Android') {
      env = 'android';
    } else {
      env = 'ios';
    } */


    let env;
    if (baseOs === 'Windows' || (window && window.document)) {
      env = 'web';
    } else if (isAndroid) {
      env = 'android';
    } else if (isIOS || isIOS13) {
      env = 'ios';
    } else {
      env = 'android';
    }

    const osNameVal = osName;
    const osVersionVal = osVersion;

    if (osNameVal === 'Android') {
      env = 'android';
    } else if (osNameVal === 'iOS') {
      env = 'ios';
    } else if (osNameVal === 'Windows' || osNameVal === 'Linux') {
      env = 'web';
    }

    //  env = 'mobile';

    //  Firebase.auth().onAuthStateChanged((user) => {
    //    if (user) {


    /*  const joinRoomFunction = Firebase.app()
      .functions()
      .httpsCallable('join4PlayerRoom');

    joinRoomFunction({
      roomId,
      userPassword: password,
      env,
    })
      .then(result => resolve(
        dispatch({
          type: 'JOINED_ROOM', data: result, roomId, password,
        }),
      ))
      .catch((err) => {
        console.log(err);
        return resolve();
      });   */
    //    }
    //  })

    return FirebaseRef.child(`joinRoomRequest/${UID}`).set({
      roomId,
      userPassword: password,
      env,
      osName: osNameVal || null,
      osVersion: osVersionVal || null,
    }).then(result => resolve(
      dispatch({
        type: 'JOINED_ROOM', data: result, roomId, password,
      }),
    )).catch((err) => {
      console.log(err);
      return resolve();
    });
  }).catch(err => ({ code: err.code })));
}

/*
let endRoomCalled = false;

export function endRoom(roomId) {
  if (Firebase === null) return () => new Promise(resolve => resolve());

  return dispatch => new Promise(resolve => Firebase.auth().onAuthStateChanged((user) => {
    if (user) {
      const promise1 = RoomsRef().child(`rooms/${roomId}/globalParams/roomClosed`).once('value');
      const promise2 = RoomsRef().child(`rooms/${roomId}/nextTimestamp`).once('value');
      const promise3 = Firebase.database().ref('.info/serverTimeOffset').once('value');

      Promise.all([promise1, promise2, promise3]).then(promisesRes => {

        const roomClosed = promisesRes[0].val() || false;
        const nextTimestamp = promisesRes[1].val();
        const serverTimeOffset = promisesRes[2].val();

          if (!roomClosed && nextTimestamp > (Date.now() + serverTimeOffset)) {
            return resolve('refetch');
          }

          if (!roomClosed && !endRoomCalled) {
            const endRoomFunction = Firebase.app().functions().httpsCallable('endRoom2');

            endRoomCalled = true;

            endRoomFunction({
              roomId,
            }).then((result) => {
              setTimeout(() => {
                endRoomCalled = false;
              }, 1500);

              Event("Room", "End room", null);

              resolve(dispatch({ type: 'END_ROOM', data: result }));
            }).catch(() => {
              setTimeout(() => {
                endRoomCalled = false;
              }, 800);
              resolve();
            })
          } else {
            console.log('endRoom already called');

            resolve();
          }
        });
    }
  }));
}  */


export function endRoom2(roomId) {
  if (Firebase === null) return () => new Promise(resolve => resolve());

  return () => new Promise((resolve) => {
    try {
      return RoomsRef.child(`rooms/${roomId}/globalParams/missedTurn`).transaction((missedTurn) => {
        if (missedTurn) {
          return;
        }

        // eslint-disable-next-line
        return true;
      }).then(() => resolve()).catch(() => resolve());
    } catch (err2) {
      return resolve();
    }
  });
}


export function leaveRoom(roomId, init) {
  console.log('leaveRoom action', roomId, init);

  if (Firebase === null) return () => new Promise(resolve => resolve());

  return dispatch => new Promise((resolve) => { // Firebase.auth().onAuthStateChanged((user) => {
    //  if (user) {
    const leaveRoomFunction = Firebase.app().functions().httpsCallable('leaveRoom');

    leaveRoomFunction({
      roomId,
      init,
    }).then((result) => {
      Event('Room', 'Leave room', null);

      return resolve(dispatch({ type: 'END_ROOM', data: result.data }));
    });
    //  }
  });
}


export function leaveRoomMenu(roomId) {
  if (Firebase === null) return () => new Promise(resolve => resolve());

  return dispatch => new Promise((resolve) => { // Firebase.auth().onAuthStateChanged((user) => {
    //  if (user) {
    const leaveRoomMenuFunction = Firebase.app().functions().httpsCallable('leaveRoomMenu');

    leaveRoomMenuFunction({
      roomId,
    }).then((result) => {
      Event('Room', 'Leave room Menu', null);
      dispatch(clearLeaveRoom());
      resolve(dispatch({ type: 'LEAVE_ROOM_MENU', data: result.data }));
    });
    //  }
  });
}


export function getProfanitiesList() {
  if (Firebase === null) return () => new Promise(resolve => resolve());

  return dispatch => new Promise(resolve => FirebaseRef.child('profanitiesList').once('value', (snapshot) => {
    const profanitiesList = snapshot.val() || {};

    return resolve(dispatch({ type: 'PROFANITIES_LIST', data: profanitiesList }));
  })).catch((err) => {
    console.log('err', err);
    // throw err.message;
  });
}


export function setProfanitiesNotification(roomId) {
  if (Firebase === null) return () => new Promise(resolve => resolve());

  return dispatch => new Promise(resolve => resolve(dispatch({ type: 'PROFANITIES_NOTIFICATION', data: roomId })));
}


export function sendChatMsg(roomId, message, hasProfanities, uid) { // , name, uid, photo
  const trimmedMessage = message.trim();
  if (Firebase === null || !roomId || !message || !trimmedMessage) return () => new Promise(resolve => resolve({ status: 'error' }));

  const sendChatFunction = Firebase.app().functions('europe-west1').httpsCallable('sendRoomChatMessage2');

  return dispatch => new Promise((resolve) => {
    sendChatFunction({
      roomId,
      message,
    }).then((result) => {
      if (hasProfanities && result.data !== 'profanities exceeded') {
        const promise1 = FirebaseRef.child(`users/${uid.toString()}/warning`).once('value');
        const promise2 = FirebaseRef.child(`users/${uid.toString()}/profanityCount`).once('value');

        Promise.all([promise1, promise2]).then(async (promisesRes) => {
          let userWarning;
          let userProfanityCount;

          promisesRes.map((promiseRes, index) => {
            if (index === 0) {
              userWarning = promiseRes.val() || 0;
            } else if (index === 1) {
              userProfanityCount = promiseRes.val() || 0;
            }
            return null;
          });
          if (parseInt(userProfanityCount, 10) + 1 >= 10) {
            const cursorExpireDate = Date.parse(moment(new Date()).add(30, 'days'));
            const warning = parseInt(userWarning, 10) + 1;

            FirebaseRef.child(`users/${uid.toString()}`).update({
              warning,
              profanityCount: 0,
              warningDate: Date.now(),
              cursor: warning >= 10 || null,
              cursorExpire: warning >= 10 ? cursorExpireDate : null,
            });
            FirebaseRef.child(`warningPlayers/${uid.toString()}`).update({
              warning: parseInt(userWarning, 10) + 1,
              warningDate: Date.now(),
            });
          } else {
            FirebaseRef.child(`users/${uid.toString()}`).update({
              profanityCount: parseInt(userProfanityCount, 10) + 1,
            });
          }

          Event('Chat', 'Send chat message', result);
          return resolve({ status: 'success', data: result.data || null });
        });
      }

      return resolve({ status: 'success', data: result.data || null });
    });
  });
}

export function setChatAsRead(roomId) {
  if (Firebase === null) return () => new Promise(resolve => resolve({ status: 'error' }));

  const UID = (
    FirebaseRef
    && Firebase
    && Firebase.auth()
    && Firebase.auth().currentUser
    && Firebase.auth().currentUser.uid
  ) ? Firebase.auth().currentUser.uid : null;

  if (!UID) return () => new Promise(resolve => resolve({ status: 'error' }));

  return () => new Promise(resolve => FirebaseRef.child(`chat/${roomId}/status`).update({
    [UID]: true,
  }).then(() => {
    resolve({ status: 'success' });
  }).catch((err) => {
    resolve({ status: 'error', mesage: err });
  }));
}

export function readChat(roomId) {
  if (Firebase === null || !roomId) return () => new Promise(resolve => resolve());

  return dispatch => new Promise((resolve) => {
    dispatch({ type: 'ROOM_CHAT_RESET', data: {} });

    FirebaseRef.child(`chat/${roomId}/messages`)
      .on('child_added', (snapshot2) => {
        const message = snapshot2.val() || null;
        const { key } = snapshot2;
        console.log(message, 'chatRoom');

        return resolve(dispatch({ type: 'ROOM_CHAT_MESSAGE_ADDED', data: { message: { ...message, messageId: key }, messageId: key, roomId } }));
      });

    return resolve();
  });
}

export function stopReadChat(roomId) {
  if (Firebase === null || !roomId) return () => new Promise(resolve => resolve());

  return () => new Promise((resolve) => {
    FirebaseRef.child(`chat/${roomId}/messages`).off();
    RoomsRef.child(`roomsStaticData/${roomId}/gifts`).off();
    RoomsRef.child(`rooms/${roomId}/gifts`).off();

    return resolve();
  });
}

export function getGifts() {
  if (Firebase === null) return () => new Promise(resolve => resolve());

  return dispatch => new Promise(resolve => FirebaseRef.child('gifts2')
    .once('value', (snapshot) => {
      const gifts = snapshot.val() || {};
      resolve(dispatch({
        type: 'GIFTS',
        data: gifts,
      }));
    }));
}


export function getEmotions(devMode) {
  if (Firebase === null) return () => new Promise(resolve => resolve());

  let emotionsPath = 'emotions2';

  if (devMode) {
    emotionsPath = 'emotionsDev';
  }

  console.log('emotionsPath', emotionsPath);

  return dispatch => new Promise(resolve => FirebaseRef.child(emotionsPath)
    .once('value', (snapshot) => {
      const emotions = snapshot.val() || {};

      //  const emotions2 = { ...emotions, ED: { image: 'https://firebasestorage.googleapis.com/v0/b/zole-app.appspot.com/o/emotions%2FFacepalm.png?alt=media&token=95e7ed07-bc0a-4a94-bb8d-86a86ca61512'}, EE: { image: 'https://firebasestorage.googleapis.com/v0/b/zole-app.appspot.com/o/emotions%2FFacepalm.png?alt=media&token=95e7ed07-bc0a-4a94-bb8d-86a86ca61512' } }

      //  console.log('emotions2', emotions2);

      resolve(dispatch({
        type: 'EMOTIONS',
        data: emotions,
      }));
    }));
}


export function getRoomGifts(roomId) {
  if (Firebase === null) return () => new Promise(resolve => resolve());

  return dispatch => new Promise(resolve => RoomsRef.child(`rooms/${roomId}/gifts`)
    .on('value', (snapshot) => {
      const roomGifts = snapshot.val() || null;

      resolve(dispatch({
        type: 'ROOM_GIFTS',
        data: { data: roomGifts, roomId },
      }));
    }));
}

export function sendGift(roomId, giftId, comment, players) {
  if (Firebase === null) return () => new Promise(resolve => resolve());

  const UID = (
    FirebaseRef
    && Firebase
    && Firebase.auth()
    && Firebase.auth().currentUser
    && Firebase.auth().currentUser.uid
  ) ? Firebase.auth().currentUser.uid : null;

  if (!UID) return () => new Promise(resolve => resolve());

  return dispatch => new Promise((resolve) => {
    const sendGiftFunction = Firebase.app().functions('europe-west1').httpsCallable('sendGift2');

    sendGiftFunction({
      roomId,
      giftId,
      comment,
      players,
    }).then((result) => {
      Event('Chat', 'Send Gift', null);

      resolve(dispatch({ type: 'SEND_GIFT', data: result, roomId }));
    });
  });
}

// let emotionTimeoutId = null;

export function setEmotion(roomId, emotion) {
  //  console.log('setEmotion', { roomId, emotion });
  if (Firebase === null) return () => new Promise(resolve => resolve());

  const UID = (
    FirebaseRef
    && Firebase
    && Firebase.auth()
    && Firebase.auth().currentUser
    && Firebase.auth().currentUser.uid
  ) ? Firebase.auth().currentUser.uid : null;

  if (!UID || !emotion || !roomId) return () => new Promise(resolve => resolve());

  return (dispatch, getState) => new Promise((resolve) => {
    /*  const setEmotionFunction = Firebase.app().functions('europe-west1').httpsCallable('setEmotion');
  
      if (emotionTimeoutId) {
        clearTimeout(emotionTimeoutId);
      }
  
      setEmotionFunction({
        roomId,
        emotion,
      }).then((result) => {
        Event("Chat", "Set Emotion", null);
  
        resolve();
      }); */

    const { game } = getState();

    const { myPos } = game;

    if (myPos) {
      RoomsRef.child(`roomsStaticData/${roomId}/playersList/${myPos}/emotion`).set(emotion);

      setTimeout(() => {
        const removeEmotionFunction = Firebase.app().functions().httpsCallable('removeEmotion');

        removeEmotionFunction({
          roomId,
        }).then(() => {
          resolve();
        });
      }, 9500);
    }
  });
}

export function toggleNewGameParam(param) {
  if (param) {
    return dispatch => new Promise(resolve => resolve(dispatch({ type: 'TOGGLE_NEW_GAME_PARAM', data: param })));
  }
  return () => new Promise(resolve => resolve());
}


export function getOtherUserInfo(uid) {
  if (!uid) {
    return () => new Promise(resolve => resolve());
  }

  return (dispatch, getState) => new Promise((resolve) => {
    const { rooms } = getState();

    if (rooms.playerInfoUpdates && rooms.playerInfoUpdates[uid] && (rooms.playerInfoUpdates[uid] + (1000 * 15) > Date.now())) {
      return resolve();
    }
    const promise1 = FirebaseRef.child(`users/${uid}/totalPnts`).once('value');
    const promise2 = FirebaseRef.child(`users/${uid}/gPlayed`).once('value');
    const promise3 = FirebaseRef.child(`users/${uid}/gWon`).once('value');
    const promise4 = FirebaseRef.child(`users/${uid}/bal`).once('value');
    const promise5 = FirebaseRef.child(`users/${uid}/name`).once('value');
    const promise6 = FirebaseRef.child(`users/${uid}/lvl`).once('value');
    const promise7 = FirebaseRef.child(`giftsHistory/${uid}`).orderByKey().once('value');
    const promise8 = FirebaseRef.child(`userAchievements/${uid}`).once('value');
    const promise9 = LeaderboardRef.child(`leaderboard/allTime/${uid}/totalPnts`).once('value');
    const promise10 = LeaderboardRef.child(`leaderboard/allTime/${uid}/pointsPos`).once('value');
    const promise11 = LeaderboardRef.child(`leaderboard/allTime/${uid}/bal`).once('value');
    const promise12 = LeaderboardRef.child(`leaderboard/allTime/${uid}/balPos`).once('value');
    const promise13 = LeaderboardRef.child(`leaderboard/allTime/${uid}/gPlayed`).once('value');
    const promise14 = LeaderboardRef.child(`leaderboard/allTime/${uid}/gPlayedPos`).once('value');
    const promise15 = LeaderboardRef.child(`leaderboard/allTime/${uid}/bgWon`).once('value');
    const promise16 = LeaderboardRef.child(`leaderboard/allTime/${uid}/sgWon`).once('value');
    const promise17 = LeaderboardRef.child(`leaderboard/allTime/${uid}/tblsWon`).once('value');
    const promise18 = LeaderboardRef.child(`leaderboard/allTime/${uid}/achvCnt`).once('value');
    const promise19 = LeaderboardRef.child(`leaderboard/allTime/${uid}/maxRndInGame`).once('value');
    const promise20 = FirebaseRef.child(`giftsHistory/${uid}`).orderByKey().limitToLast(3).once('value');
    const promise21 = FirebaseRef.child(`userAchievements/${uid}`).orderByKey().limitToLast(3).once('value');
    const promise22 = FirebaseRef.child('statistics/userCount').once('value');
    const promise23 = LeaderboardRef.child(`leaderboard/allTime/${uid}/gWonPos`).once('value');
    const promise24 = LeaderboardRef.child(`leaderboard/allTime/${uid}/sgWonPos`).once('value');
    const promise25 = LeaderboardRef.child(`leaderboard/allTime/${uid}/tblsWonPos`).once('value');
    const promise26 = LeaderboardRef.child(`leaderboard/allTime/${uid}/achvCntPos`).once('value');
    const promise27 = LeaderboardRef.child(`leaderboard/allTime/${uid}/maxRndInGamePos`).once('value');
    const promise28 = FirebaseRef.child(`users/${uid}/dateCreated`).once('value');
    const promise29 = FirebaseRef.child(`claimedAchievementsHistory/${uid}`).orderByChild('time').limitToLast(3).once('value');
    return Promise.all([promise1, promise2, promise3, promise4, promise5, promise6, promise7, promise8, promise9, promise10, promise11, promise12, promise13, promise14, promise15, promise16, promise17, promise18, promise19, promise20, promise21, promise22, promise23, promise24, promise25, promise26, promise27, promise28, promise29]).then((promisesRes) => {
      let totalPnts;
      let gPlayed;
      let gWon;
      let bal;
      let name;
      let lvl;
      let giftsHistory;
      let userAchievements;
      let scoreTotalPnts;
      let scorePointPos;
      let coinsBal;
      let coinsbalPos;
      let gamesGplayed;
      let gamesGplayedPos;
      let bgWon;
      let sgWon;
      let tblsWon;
      let achvCnt;
      let maxRndInGame;
      let giftsHistoryData;
      let userAchievementsData;
      let totalPlayers;
      let bgWonPos;
      let sgWonPos;
      let tblsWonPos;
      let achvCntPos;
      let maxRndInGamePos;
      let dateCreated;
      let achievementsHistory;

      promisesRes.map((promiseRes, index) => {
        if (index === 0) {
          totalPnts = promiseRes.val() || 0;
        } else if (index === 1) {
          gPlayed = promiseRes.val() || 0;
        } else if (index === 2) {
          gWon = promiseRes.val() || 0;
        } else if (index === 3) {
          bal = promiseRes.val() || 0;
        } else if (index === 4) {
          name = promiseRes.val() || '';
        } else if (index === 5) {
          lvl = promiseRes.val() || 0;
        } else if (index === 6) {
          giftsHistory = promiseRes.val() || null;
        } else if (index === 7) {
          userAchievements = promiseRes.val() || null;
        } else if (index === 8) {
          scoreTotalPnts = promiseRes.val() || 0;
        } else if (index === 9) {
          scorePointPos = promiseRes.val() || 0;
        } else if (index === 10) {
          coinsBal = promiseRes.val() || 0;
        } else if (index === 11) {
          coinsbalPos = promiseRes.val() || 0;
        } else if (index === 12) {
          gamesGplayed = promiseRes.val() || 0;
        } else if (index === 13) {
          gamesGplayedPos = promiseRes.val() || 0;
        } else if (index === 14) {
          bgWon = promiseRes.val() || 0;
        } else if (index === 15) {
          sgWon = promiseRes.val() || 0;
        } else if (index === 16) {
          tblsWon = promiseRes.val() || 0;
        } else if (index === 17) {
          achvCnt = promiseRes.val() || 0;
        } else if (index === 18) {
          maxRndInGame = promiseRes.val() || 0;
        } else if (index === 19) {
          giftsHistoryData = promiseRes.val() || null;
        } else if (index === 20) {
          userAchievementsData = promiseRes.val() || null;
        } else if (index === 21) {
          totalPlayers = promiseRes.val() || 0;
        } else if (index === 22) {
          bgWonPos = promiseRes.val() || 0;
        } else if (index === 23) {
          sgWonPos = promiseRes.val() || 0;
        } else if (index === 24) {
          tblsWonPos = promiseRes.val() || 0;
        } else if (index === 25) {
          achvCntPos = promiseRes.val() || 0;
        } else if (index === 26) {
          maxRndInGamePos = promiseRes.val() || 0;
        } else if (index === 27) {
          dateCreated = promiseRes.val() || '';
        } else if (index === 28) {
          achievementsHistory = promiseRes.val() || null;
        }
        return null;
      });

      console.log('achievementsHistory', achievementsHistory);

      const betterGPlayed = (100 - (gamesGplayedPos / totalPlayers) * 100).toFixed(2);
      const betterBGWon = (100 - (bgWonPos / totalPlayers) * 100).toFixed(2);
      const betterSGWon = (100 - (sgWonPos / totalPlayers) * 100).toFixed(2);
      const betterTblsWon = (100 - (tblsWonPos / totalPlayers) * 100).toFixed(2);
      const betterAchvCnt = (100 - (achvCntPos / totalPlayers) * 100).toFixed(2);
      const betterMaxRndInGame = (100 - (maxRndInGamePos / totalPlayers) * 100).toFixed(2);


      return resolve(dispatch({
        type: 'OTHER_USER_DATA',
        data: {
          totalPnts,
          gPlayed,
          gWon,
          bal,
          name,
          lvl,
          uid,
          giftsHistory,
          userAchievements,
          scoreTotalPnts,
          scorePointPos,
          coinsBal,
          coinsbalPos,
          gamesGplayed,
          gamesGplayedPos,
          bgWon,
          sgWon,
          tblsWon,
          achvCnt,
          maxRndInGame,
          userAchievementsData,
          giftsHistoryData,
          betterGPlayed,
          betterSGWon,
          betterBGWon,
          betterTblsWon,
          betterAchvCnt,
          betterMaxRndInGame,
          dateCreated,
          achievementsHistory,
        },
      }));
    });
  });
}


export function getPartyLog(roomId, party) {
  if (Firebase === null) return () => new Promise(resolve => resolve());
console.log(roomId, party)
  return dispatch => new Promise(resolve => AdminLogsRef().child(`adminLogs/rooms/${roomId}/${party}`)
    .once('value', (snapshot) => {
      const data = snapshot.val() || {};
      console.log('getPartyLog')
      console.log(data)

      return resolve(dispatch({
        type: 'PARTY_LOGS', data, roomId, party,
      }));
    }, (err) => { console.log(err); })).catch((err) => {
      console.log('err', err);
      // throw err.message;
    });
}

export const getNotifications = () => {
  console.log('notifications', 'memberActionNotifications');
  if (Firebase === null) return () => new Promise(resolve => resolve());

  const UID = (
    FirebaseRef
    && Firebase
    && Firebase.auth()
    && Firebase.auth().currentUser
    && Firebase.auth().currentUser.uid
  ) ? Firebase.auth().currentUser.uid : null;

  if (!UID) return () => new Promise(resolve => resolve());

  return dispatch => new Promise(resolve => Firebase.database().ref('notifications')
    .orderByChild('showNotification')
    .equalTo(true)
    .once('value', (snapshot) => {
      const notifications = snapshot.val() || {};
      console.log('actions notification=', notifications);
      resolve(dispatch({
        type: 'GET_NOTIFICATIONS_REPLACE',
        data: notifications,
      }));
    }));
};

export const setRoomEnded = (roomEnded) => {
  return dispatch => new Promise(resolve => resolve(dispatch({ type: 'ROOM_ENDED', data: roomEnded })));
};

export const setTournamentRoomEnded = (tournamentRoomEnded) => {
  return dispatch => new Promise(resolve => resolve(dispatch({ type: 'TOURNAMENT_ROOM_ENDED', data: tournamentRoomEnded })));
};

export function getReportedMessagesInRoom(roomId, messageKey) {
  if (Firebase === null) return () => new Promise(resolve => resolve());

  const UID = (
    FirebaseRef
    && Firebase
    && Firebase.auth()
    && Firebase.auth().currentUser
    && Firebase.auth().currentUser.uid
  ) ? Firebase.auth().currentUser.uid : null;

  if (!UID) return null;

  return dispatch => new Promise((resolve) => {
    Firebase.database().ref('reportMessages')
      .orderByChild('roomId')
      .equalTo(roomId)
      .once('value', (snapshot) => {
        const reportedMessages = snapshot.val() || {};
        return resolve(dispatch({ type: 'GET_REPORTED_MESSAGES_REPLACE', data: reportedMessages }));
      });
  });
}
export function reportMessage(reporterId, reporterName, reportedMsgPlayerId, reportedMsgPlayerName, msgKey, message, roomId) {
  if (Firebase === null) return () => new Promise(resolve => resolve());

  const UID = (
    FirebaseRef
    && Firebase
    && Firebase.auth()
    && Firebase.auth().currentUser
    && Firebase.auth().currentUser.uid
  ) ? Firebase.auth().currentUser.uid : null;

  if (!UID) return null;
  return dispatch => new Promise((resolve) => {
    Firebase.database().ref('reportMessages').push({
      reporterId,
      reporterName,
      reportedMsgPlayerId,
      reportedMsgPlayerName,
      msgKey,
      message,
      roomId,
      reportedTime: Date.now(),
    });
    return dispatch(getReportedMessagesInRoom(roomId));
  });
}
