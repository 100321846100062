import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
// import { filter, map } from 'lodash';

import { withTranslation } from 'react-i18next';
import classNames from 'classnames';

import Row from 'reactstrap/lib/Row';
import Col from 'reactstrap/lib/Col';
import Media from 'reactstrap/lib/Media';
import Button from 'reactstrap/lib/Button';
import Input from 'reactstrap/lib/Input';

import CustomModal from '../../../Components/Components/Modal';
import Toggle from '../../../Components/Components/Toggle';

// import Gift from './Gift';

import defaultImage from '../../../../../../images/Game/defaultImage.webp';
import giftBackImg from '../../../../../../images/redesign/my-info/my-info-gift-bg.png';

class SendGiftMobile extends Component {
  static propTypes = {
    sendGift: PropTypes.func.isRequired,
    playButtonSound: PropTypes.func.isRequired,
    toggle: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
    gifts: PropTypes.shape(),
    players: PropTypes.shape(),
    notificationSettings: PropTypes.shape(),
    modalOpen: PropTypes.bool,
  //  isFullscreen: PropTypes.bool,
    fourPRoom: PropTypes.bool,
    roomId: PropTypes.string,
    initialSelected: PropTypes.string,
    uid: PropTypes.string,
  }

  static defaultProps = {
    gifts: {},
    players: {},
    notificationSettings: {},
    modalOpen: false,
  //  isFullscreen: false,
    fourPRoom: false,
    roomId: null,
    initialSelected: null,
    uid: null,
  }

  constructor(props) {
    super(props);
    this.state = {
      giftId: '',
      comment: '',
      playerChecked: {
        player0: false,
        player1: false,
        player2: false,
        player3: false,
      },
      allChecked: false,
      playerKeys: ['player1', 'player2', 'player3', 'player4'],
      sendingGift: false,
    };
  }

  componentDidMount() {
  }

  componentWillReceiveProps(nextProps) {
    const { initialSelected, players } = nextProps;

    if (players && initialSelected) {
      let selected;
      if (players.player1 && initialSelected === players.player1.uid) {
        selected = 'player0';
      } else if (players.player2 && initialSelected === players.player2.uid) {
        selected = 'player1';
      } else if (players.player3 && initialSelected === players.player3.uid) {
        selected = 'player2';
      } else if (players.player4 && initialSelected === players.player4.uid) {
        selected = 'player3';
      }

      if (selected) {
        this.setState(prevState => ({
          playerChecked: {
            ...prevState.playerChecked,
            [selected]: true,
          },
        }));
      }
    }
  }

  handleChange = (event) => {
    const value = event.target.type === 'checkbox' ? event.target.checked : event.target.value;
    const { name } = event.target;

    console.log('handleChange', { value, name });

    const { fourPRoom } = this.props;

    if (name === 'allChecked') {
      const { playButtonSound, players, uid } = this.props;
      playButtonSound();

      this.setState({
        playerChecked: {
          player0: players.player1.uid !== uid ? value : false,
          player1: players.player2.uid !== uid ? value : false,
          player2: players.player3.uid !== uid ? value : false,
          player3: fourPRoom ? (players.player4.uid !== uid ? value : false) : false,
        },
        allChecked: value,
      });
    } else if (name === 'player0' || name === 'player1' || name === 'player2' || name === 'player3') {
      const { playButtonSound, players } = this.props;
      const { playerChecked } = this.state;
      playButtonSound();
      let {
        player0, player1, player2, player3,
      } = playerChecked;
      if (name === 'player0') {
        player0 = value;
      } else if (name === 'player1') {
        player1 = value;
      } else if (name === 'player2') {
        player2 = value;
      } else if (name === 'player3') {
        player3 = value;
      }
      this.setState(prevState => ({
        playerChecked: {
          ...prevState.playerChecked,
          [name]: value,
        },
        allChecked: (!!(!fourPRoom && player0 && player1 && player2) || !!(fourPRoom && player0 && player1 && player2 && player3)),
      }));
    } else if (name === 'comment') {
      const striped = value.replace(/[^A-Za-z0-9\s!?\u0020-\u0080\u0400-\u04FF\u0080-\u024F]/g, '');

      const trimmed = striped.substring(0, 100);

      this.setState({
        [name]: trimmed,
      });
    }
  }

  sendGift = () => {
    const {
      sendGift, players, roomId, playButtonSound, toggle,
    } = this.props;
    const {
      giftId, playerChecked, comment, sendingGift,
    } = this.state;

    const {
      player0, player1, player2, player3,
    } = playerChecked;

    const selectedPlayerIds = [];
    if (player0 && players.player1) {
      selectedPlayerIds.push(players.player1.uid);
    }
    if (player1 && players.player2) {
      selectedPlayerIds.push(players.player2.uid);
    }
    if (player2 && players.player3) {
      selectedPlayerIds.push(players.player3.uid);
    }
    if (player3 && players.player4) {
      selectedPlayerIds.push(players.player4.uid);
    }

    this.setState({ confirmationModal: false });

    if (!sendingGift && selectedPlayerIds.length > 0 && giftId) {
      playButtonSound();

      this.setState({ sendingGift: true });

      sendGift(roomId, giftId, comment, selectedPlayerIds).then((res) => {
        if (res === 'success') {
          toggle();
          // this.closeModal();
        }
        this.setState({
          sendingGift: false,
          playerChecked: {
            player0: false,
            player1: false,
            player2: false,
            player3: false,
          },
        });
      }).catch(() => {
        this.setState({ sendingGift: false });
      });
    }
  }

  closeModal = () => {
    const { toggle, playButtonSound } = this.props;

    playButtonSound();

    this.setState({
      giftId: '',
      comment: '',
      playerChecked: {
        player0: false,
        player1: false,
        player2: false,
        player3: false,
      },
      allChecked: false,
      confirmationModal: false,
  });

    toggle(null, false);
  }

  selectGift = (key) => {
    const { playButtonSound } = this.props;
    playButtonSound();
console.log(key, "selectGift")
    this.setState({ giftId: key });
  }

  startSendingGift = () => {
    const { notificationSettings } = this.props;

    if (notificationSettings && notificationSettings.purchaseGift === false) {
      this.sendGift();
    } else {
      this.toggleConfirmModal();
    }
  }

  toggleConfirmModal = () => {
    const { gifts } = this.props;
    const { giftId, playerChecked } = this.state;

    const gift = gifts[giftId];

    if (gift) {
      const { price } = gift;

      let totalPrice = 0;
      let selectedusersCount = 0;

      Object.keys(playerChecked).map((key) => {
        if (playerChecked[key]) {
          totalPrice += price;
          selectedusersCount += 1;
        }
        return null;
      });

      this.setState(prevState => ({
        giftNotSelected: false,
        totalPrice,
        selectedusersCount,
        confirmationModal: !prevState.confirmationModal,
      }));
    } else {
      this.setState(prevState => ({
        giftNotSelected: true,
        confirmationModal: !prevState.confirmationModal,
      }));
    }
  }

  render() {
    const {
      gifts,
      t,
      modalOpen,
      players,
      uid,
    //  isFullscreen,
    } = this.props;

    const {
      playerChecked,
      allChecked,
      giftId,
      comment,
      confirmationModal,
      totalPrice,
      selectedusersCount,
      giftNotSelected,
      playerKeys,
    } = this.state;

    console.log(playerChecked, playerKeys, players, "playerKeys");
    // let buyButton = false;
    // if (players && playerKeys) {
    //   map(playerKeys, (item, key) => { 
    //     // console.log(playerChecked[`player${key + 1}`], players[`player${key + 1}`], uid, "playerChecked");
    //     if (playerChecked[`player${key}`] && players[`player${key + 1}`] && players[`player${key + 1}`].uid === uid) {
    //       buyButton = true;
    //     }
    //   })
    // }

    return (
      <div>
        <CustomModal
          addClassName="send-gift-mobile-section"
          isOpen={modalOpen}
          toggle={this.closeModal}
          size="lg"
          title={t('gifts.gifts')}
          footer={
            // !buyButton && (
              <Button className="modal-footer-button" onClick={this.startSendingGift}>
                {t('gifts.buy')}
              </Button>
            // )
          }
          body={(
            <div className="send-gifts">
              <Row className="send-gifts-player">
                <Col xs="12" className="d-flex align-items-start mb-2 send-gifts-player-section">
                  <Toggle checked={allChecked} name="allChecked" onChange={this.handleChange} />
                  <div className={classNames('send-gifts-player-image-wrapper send-gifts-player-everyone-image-wrapper', { 'send-gifts-player-checked': allChecked })}>
                    {
                        playerKeys && playerKeys.map((key, index) => (
                          players[key] && players[key].name && players[key].uid !== uid && (
                            <Media src={players[key].photo || defaultImage} className={`send-gifts-player-image send-gifts-player-image-${index}`} />
                          )
                        ))
                      }
                  </div>
                  <div className="send-gifts-player-name">
                    {t('gifts.sendAll')}
                  </div>
                </Col>
                {playerKeys && playerKeys.map((key, index) => (
                  players[key] && players[key].name && players[key].uid !== uid && (
                    <Col xs="12" key={key} className="d-flex align-items-start mb-2 send-gifts-player-section">
                      <Toggle disabled={!players[key].name} checked={playerChecked[`player${index}`]} name={`player${index}`} onChange={this.handleChange} />
                      <div className={classNames('send-gifts-player-image-wrapper', { 'send-gifts-player-checked': playerChecked[`player${index}`] })}>
                        <Media src={players[key].photo || defaultImage} className="send-gifts-player-image" />
                      </div>
                      <div className={classNames('send-gifts-player-name', { 'send-gifts-player-checked': playerChecked[`player${index}`] })}>
                        {players[key].name}
                      </div>
                    </Col>
                  )
                ))}
              </Row>
              <Row className="send-gifts-list-mobile">
                <Col xs="12" className="send-gifts-list-mobile-section">
                  {gifts && Object.keys(gifts).map(key => (
                    <div
                      key={key}
                      className={`gifts-gift-item ${giftId === key ? 'gifts-gift-item-selected' : ''}`}
                      onClick={() => this.selectGift(key)}
                      style={{ cursor: 'pointer' }}
                    >
                      <Media src={giftBackImg} className="gifts-gift-image-bg" />
                      <Media src={gifts[key].image} className="gifts-gift-image" />
                    </div>
                  ))}
                </Col>
              </Row>
              <Row>
                <Col sm="12" className="send-gifts-comment-wrapper" style={{ fontSize: 10, paddingLeft: 0 }}>
                {/*  <div className="send-gifts-comment-header">
                    {t('gifts.comment')}
                  </div> */}
                  <Input
                    className="send-gifts-comment"
                    type="textarea"
                    name="comment"
                    placeHolder={t('gifts.commentPlaceholder')}
                    value={comment}
                    onChange={this.handleChange}
                  />
                  <div className="send-gifts-comment-length">
                    {comment ? (
                      `${100 - comment.length}/100`
                    ) : (
                      '100/100'
                    )}
                  </div>
                </Col>
              </Row>
            </div>
          )}
        />

        <CustomModal
          isOpen={confirmationModal}
          toggle={this.toggleConfirmModal}
          size="md"
          title={t('gifts.gifts')}
          footer={(
            <>
              {
                giftNotSelected ? (
                  <Button className="modal-footer-button" onClick={this.toggleConfirmModal}>
                    {t('home.close')}
                  </Button>
                ) : (
                  <Fragment>
                    {selectedusersCount === 0 ? (
                      <Button className="modal-footer-button" onClick={this.toggleConfirmModal}>
                        {t('home.close')}
                      </Button>
                    ) : (
                      <Fragment>
                        <Button className="modal-footer-button" onClick={this.sendGift}>
                          {t('common.yes')}
                        </Button>
                        <Button className="modal-footer-button" onClick={this.toggleConfirmModal}>
                          {t('common.no')}
                        </Button>
                      </Fragment>
                    )}
                  </Fragment>
                )
              }
            </>
          )}
          body={(
            <div className="send-gifts-confirmation">
              {
                giftNotSelected ? (
                  `${t('gifts.pick')}`
                ) : selectedusersCount === 0 ? (
                  `${t('gifts.noUser')}`
                ) : selectedusersCount === 1 ? (
                  `${t('gifts.confirmation')} ${totalPrice} ${t('gifts.coins')}­ `
                ) : selectedusersCount > 1 ? (
                  `${t('gifts.multipleGiftConfirmation')} ${totalPrice} ${t('gifts.coins')}­ `
                ) : (null)
              }
            </div>
          )}
        />
      </div>
    );
  }
}


const mapStateToProps = state => ({
  gifts: state.rooms.gifts || {},
  players: state.game.players,
  fourPRoom: state.game.globalParams.fourPRoom,
});

// const mapDispatchToProps = {

// };

export default connect(mapStateToProps, null)(withTranslation('common')(SendGiftMobile));
