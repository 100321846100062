import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import ReactGA from 'react-ga';

import Row from 'reactstrap/lib/Row';
import Col from 'reactstrap/lib/Col';
import Media from 'reactstrap/lib/Media';
import Button from 'reactstrap/lib/Button';
import UncontrolledTooltip from 'reactstrap/lib/UncontrolledTooltip';
import Modal from 'reactstrap/lib/Modal';
import ModalHeader from 'reactstrap/lib/ModalHeader';
import ModalFooter from 'reactstrap/lib/ModalFooter';

import closeImg from '../../../images/icons/close.png';
import { FONT_MODE, MENU_NAVIGATION } from '../../../constants/constants';
import { ModalBody } from 'reactstrap/lib';

class GiftsHistory extends React.Component {
  static propTypes = {
    giftsHistory: PropTypes.shape(),
    notificationSettings: PropTypes.shape(),
    gifts: PropTypes.shape(),
    changeTab: PropTypes.func.isRequired,
    removeGift: PropTypes.func.isRequired,
    getGiftsHistory: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
    fontMode: PropTypes.string,
  }

  static defaultProps = {
    giftsHistory: {},
    notificationSettings: {},
    gifts: {},
    fontMode: FONT_MODE.normal,
  }

  constructor(props) {
    super(props);
    this.state = {
    //  pointsProgressBar: 0,
    };
  }

  componentDidMount() {
  //  const { member } = this.props;
  }

  startRemoveGift = (giftId) => {
    const { notificationSettings } = this.props;
    if (notificationSettings && notificationSettings.removeGift === false) {
      this.approveRemove(giftId);
    } else {
      this.setState({ selectedGift: giftId, modalOpen: true });
    }
  }

  closeModal = () => {
    this.setState({ selectedGift: null, modalOpen: false });
  }

  buyMoney = () => {
    const { changeTab } = this.props;
    this.setState({ error: null, modalOpen: false, selectedGift: null });

    changeTab(MENU_NAVIGATION.buyPageMoneyPacks);
  }

  closeErrorModal = () => {
    this.setState({ error: null, modalOpen: false, selectedGift: null });
  }

  approveRemove = (passedGift) => {
    const { removeGift, getGiftsHistory } = this.props;
    const { selectedGift } = this.state;

    if (passedGift) {
      removeGift(passedGift).then((res) => {
        if (res && res.data && res.data.status === 'success') {
          getGiftsHistory();

          ReactGA.event({
            category: 'Menu',
            action: 'Remove Gift',
          });
        }

        if (res && res.data && (res.data.status === 'error' && res.data.message === 'low balance')) {
          this.setState({ error: 'lowBalance', modalOpen: false, selectedGift: null });
        } else {
          this.setState({ selectedGift: null, modalOpen: false });
        }
      });
    } else if (selectedGift) {
      removeGift(selectedGift).then((res) => {
        if (res && res.data && res.data.status === 'success') {
          getGiftsHistory();

          ReactGA.event({
            category: 'Menu',
            action: 'Remove Gift',
          });
        }

        if (res && res.data && (res.data.status === 'error' && res.data.message === 'low balance')) {
          this.setState({ error: 'lowBalance', modalOpen: false, selectedGift: null });
        } else {
          this.setState({ selectedGift: null, modalOpen: false });
        }
      });

    //  this.setState({ selectedGift: null, modalOpen: false });
    }
  }

  render() {
    const { giftsHistory, gifts, t, fontMode } = this.props;
    const { modalOpen, error } = this.state;

    return (
      <Fragment>
        <Row>
          <Col className="giftsHistory-header">
            {t('myInfo.giftsHistory')}
          </Col>
        </Row>
        <Row className="giftsHistory-row">
          {gifts && giftsHistory && Object.keys(giftsHistory).map((key, index) => (
            <Col className="giftsHistory-wrapper" key={`${key} - ${index}`} xs="2" sm="2">
              <div className="giftsHistory">
                {giftsHistory[key] && (
                  <>
                    <Media
                      className="giftsHistory-image"
                      id={`giftsHistory-${key}`}
                      trigger="hover"
                      src={gifts[giftsHistory[key].giftId] ? gifts[giftsHistory[key].giftId].image : ''}
                    />
                    <Media className="giftsHistory-remove" src={closeImg} onClick={() => this.startRemoveGift(key)} />
                    <UncontrolledTooltip
                      className="gifts-history-tooltip gifts-history-tooltip-old player-receivedGift-tooltip-old"
                      placement="bottom"
                      target={`giftsHistory-${key}`}
                      container={'div > div'}
                    >
                      <div className="giftsHistory-tooltip-from">
                        {giftsHistory[key].fromName || ''}
                      </div>
                      <div className="giftsHistory-tooltip-comment">
                        {giftsHistory[key].comment || ''}
                      </div>
                    </UncontrolledTooltip>
                  </>
                )}
              </div>
            </Col>
          ))}
        </Row>
        <Modal container={'div > div'} isOpen={modalOpen} toggle={this.closeModal} className={`notification old-modal root-font-${fontMode}`}>
          <ModalHeader
            className="notification-header"
            close={
              <Media src={closeImg} className="notification-header-close" alt="X" onClick={this.closeModal} />
              }
          >
            {/*  {!!error ? (<Alert color="danger">
                {error === 'lowBalance' ? t('myInfo.deleteGiftErrorBal') : t('myInfo.deleteGiftError')}
              </Alert>) : (null)}  */}
            {t('home.confirm')}
          </ModalHeader>
          <ModalBody>
            {t('myInfo.deleteGiftMessage')}
          </ModalBody>
          <ModalFooter className="notification-footer">
            <Button type="button" className="btn notification-footer-button" color="link" onClick={() => this.approveRemove()}>
              {t('common.yes')}
            </Button>
            <Button type="button" className="btn notification-footer-button" color="link" onClick={this.closeModal}>
              {t('common.no')}
            </Button>
          </ModalFooter>
        </Modal>

        <Modal container={'div > div'} isOpen={!!error} toggle={this.closeErrorModal} className={`notification old-modal root-font-${fontMode}`}>
          <ModalHeader
            className="notification-header"
            close={
              <Media src={closeImg} className="notification-header-close" alt="X" onClick={this.closeErrorModal} />
              }
          >
            {t('home.notifications')}
          </ModalHeader>
          <ModalBody>
            {error === 'lowBalance' ? t('myInfo.notEnoughMoneyRemoveGift') : t('myInfo.deleteGiftError')}
          </ModalBody>
          <ModalFooter className="notification-footer">
            {error === 'lowBalance' ? (
              <Button className="notification-footer-button" color="link" onClick={this.buyMoney}>{t('myInfo.buyMoney')}</Button>
            ) : (null)}
            <Button type="button" className="btn notification-footer-button" color="link" onClick={this.closeErrorModal}>
              {t('tournaments.close')}
            </Button>
          </ModalFooter>
        </Modal>
      </Fragment>
    );
  }
}


export default GiftsHistory;
